import { useEffect, useState } from "react";
import Barna from "../../../Assests/Images/Barna.svg"
import HImage from "../../../Assests/Images/NewHarvardlogo.png"
import settingIcon from "../../../Assests/Images/settingIcon.svg"

export default function Header() {
    return (
      <>
        <div className="church-health-header-container">
          <div className="ch-heading">Church Health</div>
          <div className="header-section2">
            <div className="images-container">
              <div className="Image-1">
                <img src={Barna} alt="Barna logo" />
              </div>
              <div className="Image-2">
                <img src={HImage} alt="Harvard logo" />
              </div>
            </div>
            <div className="header-text2">
              Research backed by Barna, the Human Flourishing Program 
              and Gloo point to 7 dimensions of human flourishing.
            </div>
          </div>
        </div>
    </>
    );
};
