import React, { useState } from 'react';
import { BarChart, Bar, XAxis, LabelList, ResponsiveContainer, Cell } from 'recharts';
import { useNavigate } from 'react-router-dom';
const NonComparisonBarChart = ({
  data,
  isDeployedDone,

}) => {
  const navigate = useNavigate();
  const [hoveredBar, setHoveredBar] = useState(null);

  const handleBarClick = (event, dataKey) => {
    const clickedBar = event?.activePayload?.[0]?.payload;
    if (clickedBar && clickedBar[dataKey]) {
      navigate(`/dimension-details/${clickedBar?.catId}/${encodeURIComponent(clickedBar?.name)}`, {
        state: {
          from: 'NonComparisonBarChart',
          urldata: clickedBar,
          isDeployedDone: isDeployedDone,
        },
      });
    } else {
      console.error("No data found for the clicked bar");
    }
  };

  return (
    <div className="comparison-bar-chart">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 25, right: 30, left: 20, bottom: 5 }}
          onClick={(e) => handleBarClick(e, "mydata")}
          barGap={12}
          barSize={50}
        >
          <XAxis
            padding={{ top: 50 }}
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={renderCustomAxisTick}
            height={60}
            interval={0}
          />
          <Bar
            dataKey="mydata"
            fill="#C581FF"
            background={{ fill: "#F5F5F5", radius: [4, 4, 0, 0] }}
            radius={[4, 4, 0, 0]}
          >
            {data.map((entry, cellIndex) => (
              <Cell
                key={`cell-${cellIndex}`}
                onMouseEnter={() => setHoveredBar(cellIndex)}
                onMouseLeave={() => setHoveredBar(null)}
                style={{
                  transition: 'opacity 0.3s ease',
                  opacity: hoveredBar !== null && hoveredBar !== cellIndex ? 0.5 : 1,
                }}
              />
            ))}
            <LabelList dataKey="mydata" position="top" content={<CustomLabelWithBackground />} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
const renderCustomAxisTick = ({ x, y, payload }) => {
  // Split text if it contains a space
  const words = payload?.value?.split(' ') || [];
  const line1 = words[0];
  const line2 = words.slice(1).join(' ');

  const getTextWidth = (text, fontSize = 16, fontFamily = "F37 Jan") => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  };

  // Calculate the maximum width needed for the lines of text
  const textWidth = Math.max(getTextWidth(line1), getTextWidth(line2));
  const boxWidth = textWidth + 2 * 14.5;

  return (
    <g transform={`translate(${x},${y + 18})`}>
      {/* Background box */}
      <rect x={-boxWidth / 2} y={-32.95 / 2} width={boxWidth} height={line2 === '' ? 32.95 : 51} rx={line2 === '' ? 16 : 22} ry={line2 === '' ? 16 : 22} fill="#FAFAFA" />
      {/* Text lines */}
      <text x={0} y={5} textAnchor="middle" fontFamily="F37 Jan" fontSize="18px" fontWeight={700} fill="#171717" color="#171717">
        {line1}
      </text>
      {line2 && (
        <text x={0} y={21.6} textAnchor="middle" fontFamily="F37 Jan" fontSize="18px" fontWeight={700} fill="#171717" color="#171717">
          {line2}
        </text>
      )}
    </g>
  );
};
const CustomLabelWithBackground = (props) => {
  const { x, width, value, ...rest } = props;
  const y = 20;
  return (
    <text fontFamily="Poppins" fontSize="14px" fontWeight={500} textAnchor="middle" {...rest} x={x + width / 2} y={y} color="#171717" fill="#171717">{value}</text>
  );
};
export default NonComparisonBarChart;