import { useEffect, useRef } from "react";
import LimitedTimeBanner from "../../../components/ChurchHealth/LimitedTimeBanner/LimitedTimeBanner";

export function Header({ showLimitedTimeBanner, setShowLimitedTimeBanner, isDeployedDone }) {
  const limitedTimeBannerRef = useRef(null);

  useEffect(() => {
    if (!limitedTimeBannerRef.current || !isDeployedDone) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowLimitedTimeBanner(entry.isIntersecting); // Set true when in view, false when out
      },
      { threshold: 0 } // Trigger when at least 10% of the banner is visible
    );

    observer.observe(limitedTimeBannerRef.current);

    return () => observer.disconnect();
  }, [setShowLimitedTimeBanner, isDeployedDone]);

  return (
    <div className="mobile-header-container" >
      {isDeployedDone &&  <div className="limitedtimebanner-container" ref={limitedTimeBannerRef} style={{padding:showLimitedTimeBanner ?'10px 11px 0':'0'}}>
        <LimitedTimeBanner />
      </div>}
      
      <div className="mobile-header-text"style={{ position: showLimitedTimeBanner ? "relative" : "fixed" , top : "0"  }}>
        <p className="headername">Church Health</p>
      </div>
    </div>
  );
}
