import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { addDays, subDays, subWeeks, subMonths, subYears, isBefore, isAfter } from 'date-fns';
import { format } from 'date-fns';
import { scaleLinear } from "d3-scale";

const getAllMonths = () => {
  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push({ date: new Date(2024, i, 1).toISOString(), green: 0, blue: 0, red: 0, ba: 0 });
  }
  return months;
};

const getLatestAvailableData = (data) => {
  if (data.length === 0) return [];
  const latestDate = data[data.length - 1].date;
  return data.filter(d => format(new Date(d.date), 'yyyy-MM-dd') === format(new Date(latestDate), 'yyyy-MM-dd'));
};

const prepareHourlyData = (chartData) => {
  const hourlyData = [];
  const today = new Date();

  // Take the last 24 elements from chartData
  const validData = chartData?.slice(-24) || [];

  for (let i = 0; i < 24; i++) {
    const dataPoint = validData?.[i] || {};
    const hourDate = new Date(today);
    hourDate.setHours(i, 0, 0, 0);

    hourlyData.push({
      date: dataPoint?.hour || hourDate.toISOString(),
      blue: dataPoint?.count || 0,
      green: 0,
      red: 0,
    });
  }

  return hourlyData;
};


const yScale = scaleLinear();

const ResultArea = ({ data, filter, customRange, chartDataToday }) => {

  const [activeIndex, setActiveIndex] = useState(-1);
  const [position, setPosition] = useState({x:0, y:0})
  const [nearestValue, setNearestValue] = useState(0);
  const filteredData = useMemo(() => {
    const today = new Date().setHours(0,0,0,0);

    switch (filter) {
      case "1D": {
        return prepareHourlyData(chartDataToday);
      }
      case "1W":
        return data.filter((d) => {
          return !isBefore(new Date(d.date), subWeeks(today, 1))
        });
      case "1M":
        return data.filter((d) =>
          isAfter(new Date(d.date), subMonths(today, 1))
        );
      case "6M":
        return data.filter((d) =>
          isAfter(new Date(d.date), subMonths(today, 6))
        );
      case "1Y": {
        return data.filter((d) =>
          isAfter(new Date(d.date), subYears(today, 1))
        );
      }
      case "all":
        return data;
      case "custom-range": {
        const [startDate, endDate] = customRange;
        return startDate && endDate
          ? data.filter(
              (d) =>
                !isBefore(
                  new Date(d.date),
                  new Date(format(startDate, "yyyy-MM-dd"))
                ) &&
                !isAfter(
                  new Date(d.date),
                  new Date(format(endDate, "yyyy-MM-dd"))
                )
            )
          : data;
      }
      default:
        return data;
    }
  }, [data, filter, customRange, chartDataToday]);

  const xAxisFormatter = (tickItem) => {
    const date = new Date(tickItem);
    switch (filter) {
      case '1D':
        return format(date, 'HH:mm');
      case '1W':
        return format(date, 'EEE');
      case '1M':
        return format(date, 'd MMMM');
      case '1Y':
        return format(date, 'MMMM');
      default:
        return format(date, 'MMMM d');
    }
  };

  const CustomTooltip = (props) => {
    const { active, payload, coordinate, activeIndex, nearestValue } = props;
    if (active && payload && payload.length && payload[activeIndex]) {

      const tooltipValue = payload.map(p => p.value).reduce(function(prev, curr) {
        const curVal = curr - nearestValue;
        const prevVal = prev - nearestValue;
        const activeDotRadius = -4;
        if ( curVal < activeDotRadius ) return prev;
        else if (prevVal < activeDotRadius ) return curr;
        return Math.abs(curr - nearestValue) < Math.abs(prev - nearestValue) ? curr : prev;
      });

      return (
        <div className={"areaChartTooltip"}>
          {tooltipValue}
        </div>
      );
    }
  }

  return (
    <div style={{ width: '100%', height: '190px' ,marginLeft: '12px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={filteredData} margin={{ left: 15, right: 15, top: 15 }} onMouseMove={(e) => {
          const {x = 0,y = 0} = e?.activeCoordinate || {};

          const nearestValue = yScale.invert(y);
          setNearestValue(nearestValue);

          setPosition({x: x - 25,y: y - 45});
        }}>
          <defs>
            <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#B3E2C380" stopOpacity={0.5} />
              <stop offset="100%" stopColor="#FFFFFF80" stopOpacity={0.5} />
            </linearGradient>
            <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#C3DDFD80" stopOpacity={0.5} />
              <stop offset="100%" stopColor="#FFFFFF80" stopOpacity={0.5} />
            </linearGradient>
            <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#FECACA80" stopOpacity={0.5} />
              <stop offset="100%" stopColor="#FFFFFF80" stopOpacity={0.5} />
            </linearGradient>
            <linearGradient id="colorfour" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#DDD6FE80" stopOpacity={0.5} />
              <stop offset="100%" stopColor="#FFFFFF80" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis 
            dataKey="date" 
            tickFormatter={xAxisFormatter} 
            tick={{ fontSize: 12, fill: '#737373' }} 
            axisLine={false} 
          />
          <YAxis tick={{ fontSize: 12, fill: '#737373' }} axisLine={false} scale={yScale} />
          <Tooltip content={<CustomTooltip activeIndex={activeIndex} nearestValue={nearestValue} />} position={position}/>
          <Area type="monotone" dot={false} dataKey="green"  activeDot={{r: 4, fill: "#fff", stroke: "green", onMouseOver: () => setActiveIndex(0)}} stroke="#41B768" fill="url(#colorGreen)" />
          <Area type="monotone" dot={false} dataKey="blue" activeDot={{r: 4, fill: "#fff", stroke: "blue", onMouseOver: () => setActiveIndex(1)}} stroke="#3F83F8" fill="url(#colorBlue)" />
          <Area type="monotone" dot={false} dataKey="red" activeDot={{r: 4, fill: "#fff", stroke: "red", onMouseOver: () => setActiveIndex(2)}} stroke="#EF4444" fill="url(#colorRed)" />
          <Area type="monotone" dot={false} dataKey="ba" activeDot={{r: 4, fill: "#fff", stroke: "#8B5CF6", onMouseOver: () => setActiveIndex(3)}} stroke="#8B5CF6" fill="url(#colorfour)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ResultArea;
