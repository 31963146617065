import React, { useEffect, useState } from "react";
import { ReactComponent as AiInsightIcon } from "../../../Assests/Images/ChurchHealth/icons/AiInsightsIcon.svg";
import Arrow from "../../../Assests/Images/arrow-right-black.svg";
import ComparisonBarChart from "../ComparisonBarChart/ComparisonBarChart";
import NonComparisonBarChart from "../../ChurchHealth/NonComparisonBarChart/NonComparisonBarChart";
import { useNavigate } from "react-router-dom";
import { AIChurchThriving } from "../AIChurchThriving/AIChurchThriving";
import { ShimmerBarChart } from "shimmer-effects-react";

const data = {
  Nurturing: [
    { variable: "cpcommunity", name: "Connected Community", mydata: 32 },
    { variable: "cpworship", name: "Worship Experience", mydata: 34 },
    { variable: "cptrusted", name: "Trusted Leaders", mydata: 84 },
    { variable: "cpbible", name: "Bible Centeredness", mydata: 27 },
    { variable: "cpformation", name: "Spiritual Formation", mydata: 48 },
    { variable: "cpprayer", name: "Prayer Culture", mydata: 63 },
  ],
  Sending: [
    { variable: "cpfaith", name: "Faith Sharing", mydata: 44 },
    { variable: "cpserving", name: "Serving Others", mydata: 74 },
    { variable: "cpsteward", name: "Holistic Stewardship", mydata: 34 },
    { variable: "cpleaddev", name: "Leadership Development", mydata: 84 },
    { variable: "cpimpact", name: "Social Impact", mydata: 31 },
  ],
  Leading: [
    { variable: "cpfuture", name: "Future Focused", mydata: 21 },
    { variable: "cpdata", name: "Data Informed", mydata: 45 },
    { variable: "cpresource", name: "Resource Stability", mydata: 97 },
    { variable: "cpteam", name: "Team Health", mydata: 10 },
  ],
};

const ChurchThriving = ({
  isDeployedDone,
  primaryData,
  secondaryData,
  comparison,
  primaryColor,
  dimensionCatIds,
  primaryLabel,
  secondaryLabel,
  isChurch,
}) => {
  const [activeCard, setActiveCard] = useState("Nurturing");
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleCardClick = (title) => {
    setActiveCard(title);
  };

  useEffect(() => {
    setIsLoading(true);
    if (primaryData && secondaryData && Array.isArray(dimensionCatIds)) {
      const churchThrivingVariables = {
        Nurturing: [
          "cpcommunity",
          "cpworship",
          "cptrusted",
          "cpbible",
          "cpformation",
          "cpprayer",
        ],
        Sending: ["cpfaith", "cpserving", "cpsteward", "cpleaddev", "cpimpact"],
        Leading: ["cpfuture", "cpdata", "cpresource", "cpteam"],
      };

      const primAverageScores = {};
      const secAverageScores = {};

      const currentVariables = churchThrivingVariables[activeCard];
      currentVariables.forEach((variable) => {
        const item = secondaryData.find((data) => data.variable === variable);
        secAverageScores[variable] = Math.floor(item?.average_score || 0);

        const prItem = primaryData.find((data) => data.variable === variable);
        primAverageScores[variable] = Math.floor(prItem?.average_score || 0);
      });

      const newData = data[activeCard].map(({ name, variable }) => {
        const dimension = dimensionCatIds.find(
          (dim) => dim.variable === variable
        );
        return {
          name,
          mydata: primAverageScores[variable],
          nationalaverage: secAverageScores[variable],
          catId: dimension?.catId || null,
        };
      });

      setChartData(newData);
      setIsLoading(false);
    }
  }, [primaryData, secondaryData, activeCard]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  const convertMarkdownToHtml = (text) => {
    if (!text) return null;

    const parts = text.split(" ");
    const highlightWords = [
      "strong",
      "community",
      "empowered",
      "mental",
      "health",
    ];

    return parts.map((part, index) => {
      const shouldHighlight = highlightWords.includes(part.toLowerCase());

      return shouldHighlight ? (
        <b className="highlight-blue" key={index}>
          {part + " "}
        </b>
      ) : (
        <span key={index}>{part + " "}</span>
      );
    });
  };
  return (
    <div className="church-thriving">
      <div className="church-thriving__title">Church Thriving</div>
      <p className="church-thriving__subtitle">
        15 dimensions of <strong>Church-specific</strong> health metrics, broken into 3 buckets. What are the deeper drivers of my church health?
      </p>
      <div className="church-thriving__metric-card-container">
        <MetricCard
          active={activeCard === "Nurturing"}
          title="Nurturing"
          description="How are we cultivating life within our congregation?"
          onClick={() => handleCardClick("Nurturing")}
        />
        <MetricCard
          active={activeCard === "Sending"}
          title="Sending"
          description="How are we equipping people for ministry outside of our church?"
          onClick={() => handleCardClick("Sending")}
        />
        <MetricCard
          active={activeCard === "Leading"}
          title="Leading"
          description="How are we strengthening our leadership culture and organizational operations?"
          onClick={() => handleCardClick("Leading")}
        />
      </div>
      <div className="church-thriving__chart-section">
        {isLoading ?
          <div className="barcontainershimmer" style={{ paddingTop: 10, height: "367px", width: "100%", opacity:0.5 }}>
            <ShimmerBarChart
              mode="light"
              chartType="random"
              barWidth="5%"
            />
          </div>
          : comparison ? (
            <ComparisonBarChart
              isDeployedDone={isDeployedDone}
              data={chartData}
              primaryColor={primaryColor}
              isChurch={isChurch}
              comparison={comparison}
            />
          ) : (
            <NonComparisonBarChart
              data={chartData}
              isDeployedDone={isDeployedDone}
            />
          )}
      </div>
      {comparison && (
        <div className="church-thriving__chart-legend">
          <div className="church-thriving__chart-legend-item">
            <span
              className="grey-dot"
              style={{ backgroundColor: primaryColor }}
            ></span>
            {primaryLabel}
          </div>
          <div className="church-thriving__chart-legend-item">
            <span className="green-dot"></span>
            {secondaryLabel}
          </div>
        </div>
      )}
      <div className="church-thriving__insights">
        <AIChurchThriving isDeployedDone={isDeployedDone} isChurch={isChurch} />
      </div>
    </div>
  );
};

export default ChurchThriving;

const MetricCard = ({ children, active, title, description, onClick }) => {
  return (
    <div
      className={`metric-card ${active ? "metric-card--active" : ""}`}
      onClick={onClick}
    >
      <div className="metric-card__title">{title}</div>
      <p className="metric-card__description">{description}</p>
      {children}
    </div>
  );
};
