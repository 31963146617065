import React, { useEffect, useState } from 'react'
import ring from "../../../Assests/Images/ring.svg"
import baby from "../../../Assests/Images/baby.svg"
import cards from "../../../Assests/Images/cards.svg"
import heartCrack from "../../../Assests/Images/heart-crack.svg"
import personCane from "../../../Assests/Images/person-cane.svg"
import arrowright from '../../../Assests/Images/arrow-right-black-view.svg';
import demoCTA from "../../../Assests/Images/demoCTA.svg"
import ChruchHealthCards from '../ChruchHealthCards/ChruchHealthCards'
import { useNavigate } from 'react-router-dom'
import { navigateToCircles } from '../../../utils'

const Demographics = ({ isDeployedDone, primaryData, secondaryData, comparison, appliedFiltersPrimary, appliedFiltersSecondary, primaryLabel, secondaryLabel , isChurch }) => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handelViewAll = () => {
    navigate('/comparative-demographic-details', {
      state: {
        from: 'Demographics',
        isDeployedDone: isDeployedDone,
        isComparisonBarChart: comparison,
        isChurch,
      },
    });
  };
  console.log("check1",isLoading);
  useEffect(() => {
    if ((isDeployedDone && primaryData && Object.keys(primaryData).length > 0 && secondaryData && Object.keys(secondaryData).length > 0) || (!comparison && primaryData && Object.keys(primaryData).length > 0) || (!isDeployedDone && secondaryData && Object.keys(secondaryData).length > 0) ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [isDeployedDone, primaryData, secondaryData, comparison]);
  



  return (
    <div className='Demographics-container'>
      <div style={{overflow:"auto", padding: '0 8px'}}>
        <div className="demographics-title">
          <div className="left">
            <p className="heading">Demographics</p>
            <p className="subheading">Learn more about the characteristics of your community.</p>
          </div>
          <div className="right">
            <button className="cta-viewall" onClick={handelViewAll}>View all <img src={arrowright} alt="" style={{ width: "16px" }} /></button>
          </div>
        </div>
        <div className='Demographics-cards'>
          <ChruchHealthCards icon={ring} title={"Marriage"} description={"What percentage of congregants are married?"} isDeployedDone={isDeployedDone} data1={primaryData && primaryData.cpmarriage} data2={secondaryData ? secondaryData.cpmarriage : null} isComparisonBarChart={comparison} primaryLabel={primaryLabel} secondaryLabel={secondaryLabel} isLoadingcards={isLoading} />
          <ChruchHealthCards icon={baby} title={"Parenthood"} description={"What percentage of congregants are parents?"} isDeployedDone={isDeployedDone} data1={primaryData && primaryData.cpparenthood} data2={secondaryData ? secondaryData.cpparenthood : null} isComparisonBarChart={comparison} primaryLabel={primaryLabel} secondaryLabel={secondaryLabel} isLoadingcards={isLoading} />
          {isDeployedDone && <ChruchHealthCards icon={heartCrack} title={"Divorce"} description={"What percentage of congregants are divorced?"} isDeployedDone={isDeployedDone} data1={primaryData && primaryData.cpdivorced} data2={secondaryData ? secondaryData.cpdivorced : null} isComparisonBarChart={comparison} primaryLabel={primaryLabel} secondaryLabel={secondaryLabel} isLoadingcards={isLoading}/>}
          {/* {isDeployedDone && !secondaryData && <ChruchHealthCards icon={personCane} title={"Seniors"} description={"What percentage of congregants are greater than 55 years old?"} isDeployedDone={isDeployedDone} data1={primaryData && primaryData.cpsenior} data2={secondaryData ? secondaryData.cpsenior : null} isComparisonBarChart={comparison} />} */}
          {!isDeployedDone && <div className='ch-card3'>
            <div className='ch-card3-img-container'>
              <img src={cards} alt="ring" />
            </div>
            <div className='ch-card3-detail-container'>
              <div>
                <div className='ch-card3-description'>
                  <p>Learn more about your people’s background.</p>
                </div>
                <button className='ch-card3-cta' onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}>
                  <p className='ch-card3-cta-title'>{isChurch ? "Deploy Assessment" : "Create your Circle"}</p>
                  <img src={demoCTA} alt="scrollerRight" />
                </button>
              </div>
            </div>
          </div>}

        </div>
      </div>
    </div>
  )
}

export default Demographics