import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import Header from "../../components/ChurchHealth/Header/Header";
import FilterCard from "../../components/FilterCard/FilterCard";
import PeopleFlourishing from "../../components/ChurchHealth/PeopleFlourishing/PeopleFlourishing";
import GrowthGoals from "../../components/GrowthGoals/GrowthGoals";
import ChurchThriving from "../../components/ChurchHealth/ChurchThriving/ChurchThriving";
import Demographics from "../../components/ChurchHealth/Demographics/Demographics";
import {
  useChartData,
  useChurchHealthDemographicsData,
  useChurchHealthGrowthGoals,
  useOrgStatus,
} from "../../hooks/useQueryAPI";
import AuthContext from "../../store/AuthContext";
import { useNavigate } from "react-router-dom";
import ChurchHealthMobile from "../../components/ChurchHealthMobile/ChurchHealthMobile";
import { useSearchParams } from "react-router-dom";
import { getAllCircleIds } from "../../utils";
import LimitedTimeBanner from "../../components/ChurchHealth/LimitedTimeBanner/LimitedTimeBanner";

const ChurchHealth = () => {
  const [appliedFiltersPrimary, setAppliedFiltersPrimary] = useState({
    timeline: "allTime",
    dataset: "My Church",
  });
  const [appliedFiltersSecondary, setAppliedFiltersSecondary] = useState({
    timeline: "allTime",
    dataset: "National Average",
  });
  const [comparison, setComparison] = useState(true);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadData, setLoadData] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isChurch, setIsChurch] = useState(true);
  const [isFilterSticky, setIsFilterSticky] = useState(false);
  const bannerRef = useRef(null);

  const code = searchParams?.get('code');

  useEffect(() => {
    let filters = {}
    try {
      filters = JSON.parse(localStorage.getItem("church-health-fitlers"));
      if (filters) {
        if (filters?.appliedFiltersPrimary) setAppliedFiltersPrimary(filters.appliedFiltersPrimary);
        if (filters?.appliedFiltersSecondary) {
          setAppliedFiltersSecondary(filters.appliedFiltersSecondary)
        }
        if (filters.hasOwnProperty("comparison")) setComparison(!!filters?.comparison)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])


  useEffect(() => {
    const handleScroll = () => {
      if (!bannerRef.current){
        return;
      }
      const bannerBottomOffset = bannerRef.current.getBoundingClientRect().bottom;
      if (bannerBottomOffset <= 77) {
        setIsFilterSticky(true);
      } else {
        setIsFilterSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, []);


  useEffect(() => {
    localStorage.setItem("church-health-fitlers", JSON.stringify({ appliedFiltersPrimary, appliedFiltersSecondary, comparison }));
  }, [appliedFiltersPrimary, appliedFiltersSecondary, comparison])

  const getCircleIds = (filters = {}) => filters?.dataset === "Circles" ? getAllCircleIds(authCtx?.circles || [], filters?.circleType) : [];

  const {
    isLoading: isOrgStatusLoading,
    data: orgStatusData = [],
    error,
    isError
  } = useOrgStatus({orgId: authCtx.orgId, token: authCtx.token}, loadData);

  const { data: primaryData } = useChartData(
    {
      orgIds: [authCtx.orgId, ...getCircleIds(appliedFiltersPrimary)],
      dataset: appliedFiltersPrimary?.dataset,
      timeline: appliedFiltersPrimary?.timeline,
      age: appliedFiltersPrimary?.ageRange || "",
      gender: appliedFiltersPrimary?.gender || "",
    },
    !isOrgStatusLoading && loadData
  );

  const { data: secondaryData } = useChartData(
    {
      orgIds: [authCtx.orgId, ...getCircleIds(appliedFiltersSecondary)],
      dataset: appliedFiltersSecondary?.dataset,
      timeline: appliedFiltersSecondary?.timeline,
      age: appliedFiltersSecondary?.ageRange || "",
      gender: appliedFiltersSecondary?.gender || "",
    },
    !isOrgStatusLoading && loadData && comparison
  );

  const { data: primaryDemographicsData } = useChurchHealthDemographicsData(
    {
      orgIds: [authCtx.orgId, ...getCircleIds(appliedFiltersPrimary)],
      dataset: appliedFiltersPrimary?.dataset,
      timeline: appliedFiltersPrimary?.timeline,
      age: appliedFiltersPrimary?.ageRange || "",
      gender: appliedFiltersPrimary?.gender || "",
    },
    !isOrgStatusLoading && loadData
  );

  const { data: secondaryDemographicsData } = useChurchHealthDemographicsData(
    {
      orgIds: [authCtx.orgId, ...getCircleIds(appliedFiltersSecondary)],
      dataset: appliedFiltersSecondary?.dataset,
      timeline: appliedFiltersSecondary?.timeline,
      age: appliedFiltersSecondary?.ageRange || "",
      gender: appliedFiltersSecondary?.gender || "",
    },
    !isOrgStatusLoading && loadData && comparison
  );

  const { data: primaryGrowthGoalsData } = useChurchHealthGrowthGoals(
    {
      orgIds: [authCtx.orgId, ...getCircleIds(appliedFiltersPrimary)],
      dataset: appliedFiltersPrimary?.dataset,
      timeline: appliedFiltersPrimary?.timeline,
      age: appliedFiltersPrimary?.ageRange || "",
      gender: appliedFiltersPrimary?.gender || "",
    },
    (!isOrgStatusLoading && loadData)
  );

  const { data: secondaryGrowthGoalsData } = useChurchHealthGrowthGoals(
    {
      orgIds: [authCtx.orgId, ...getCircleIds(appliedFiltersSecondary)],
      dataset: appliedFiltersSecondary?.dataset,
      timeline: appliedFiltersSecondary?.timeline,
      age: appliedFiltersSecondary?.ageRange || "",
      gender: appliedFiltersSecondary?.gender || "",
    },
    !isOrgStatusLoading && loadData && comparison
  );

  useEffect(() => {

    if (authCtx.isLoggedIn && authCtx.orgId != 0 && authCtx.glooOrgId != 0) {
      if ( authCtx.orgType === "network" ) {
        setIsChurch(false);
        setAppliedFiltersPrimary({
          timeline: "allTime",
          dataset: "Circles",
          circleType: "all"
        })
      }
      setLoadData(true)
    } else if (code && code != "" && code != undefined) {
      // get token
      console.log("Getting token");
      const payload = {
        code: code,
        path: window.location.pathname
      }

      fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/oauth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
        .then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == 'success') {
            const tokenExpiration = new Date(new Date().getTime() + 7200000);
            // console.log("We are here");
            // console.log(data.data.accessToken);
            // console.log(tokenExpiration);
            authCtx.login(data.data.accessToken, tokenExpiration);

            fetch(`${process.env.REACT_APP_API_URI}/v5/integrations/gloo/userinfo`, {
              method: 'GET',
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + data?.data?.accessToken }
            })
            .then(response => {
              return response.json();
            }).then(data => {
              console.log(data);
              if (data.status == 'success') {
                authCtx.update(data?.data?.glooUserId, data?.data?.glooOrgId, data?.data?.orgName, data?.data?.orgId, data?.data?.email, data?.data?.name, data?.data?.orgType, data?.data?.circles);
                if ( data?.data?.orgType === "network" ) {
                  setIsChurch(false);
                  setAppliedFiltersPrimary({
                    timeline: "allTime",
                    dataset: "Circles",
                    circleType: "all"
                  })
                }
                setLoadData(true)
              }
            }).catch(err => {
              console.error("no success")
              return { status: "failure", error: err.message }
            });

          }
        }).catch(err => {
          console.error(err)

        });
    } else {
      window.location.href = "https://app.gloo.us/oauth/authorize?client_id=92913.f1f2c96dc3e967a16989beeb58aa9f07&redirect_uri=https://dashboard.survey.church/home&response_type=code"
    }

  }, [authCtx.orgId, authCtx.orgId, authCtx.glooOrgId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isOrgStatusLoading || !loadData) {
    return (
      <p className="loading">
        <CircularProgress size={60} style={{ color: "#000000" }} />
      </p>
    );
  }

  if (!isOrgStatusLoading && loadData && isError && error.message === "no-records") {
    if(!isChurch || authCtx.orgType == "network") {
      navigate("/network-health-deploy");
    } else {
      navigate("/church-health-deploy");
    }
  }

  return windowWidth < 560 ? (
    <ChurchHealthMobile
      comparison={comparison}
      isDeployedDone={true}

      appliedFiltersPrimary={appliedFiltersPrimary}
      setAppliedFiltersPrimary={setAppliedFiltersPrimary}
      appliedFiltersSecondary={appliedFiltersSecondary}
      setAppliedFiltersSecondary={setAppliedFiltersSecondary}
      setComparison={setComparison}

      peopleFlourishingprimaryData={
        primaryData?.data?.[appliedFiltersPrimary.timeline] || []
      }
      peopleFlourishingsecondaryData={
        !comparison
          ? []
          : secondaryData?.data?.[appliedFiltersSecondary?.timeline] || []
      }

      growthGoalsPrimaryData={primaryGrowthGoalsData || []}
      growthGoalsSecondaryData={!comparison ? [] : secondaryGrowthGoalsData || []}

      demographicsprimaryData={primaryDemographicsData || null}
      demographicssecondaryData={!comparison ? null : secondaryDemographicsData || null}

      dimensionCatIds={
        secondaryData?.data?.["dimensionCatIds"] ||
        primaryData?.data?.["dimensionCatIds"]
      }
      primaryLabel={appliedFiltersPrimary["dataset"] === 'My Church' ? 'My Data' : appliedFiltersPrimary["dataset"]}
      secondaryLabel={appliedFiltersSecondary["dataset"] === 'My Church' ? 'My Data' : appliedFiltersSecondary["dataset"]}
      isChurch={isChurch}
    />
  ) : (
    <div className="church-health">
      <Header />
      <div className="church-health__banner"> </div>
      <div ref={bannerRef} >
      <LimitedTimeBanner />  
      </div>
      <div className="filters-container"> 
      <div className={`church-health__Filters-Section ${isFilterSticky ? "church-health-deploy-sticky" : ""}`}>
        <FilterCard filterType="leftfilter" isDeployedDone={true} appliedFilters={appliedFiltersPrimary} setAppliedFilters={setAppliedFiltersPrimary}
        />
        <FilterCard filterType="rightfilter" isDeployedDone={true} appliedFilters={appliedFiltersSecondary || {}} setAppliedFilters={setAppliedFiltersSecondary} comparison={comparison}
          setComparison={(value) => {
            setComparison(value);
            if (!value) {
              setAppliedFiltersSecondary(null);
            } else {
              setAppliedFiltersSecondary({
                timeline: "allTime",
                dataset: "National Average",
              });
            }
          }}
        />
      </div>
      </div>
      
      <div className="church-health__people-flourishing"  style={{ marginTop:isFilterSticky ? '140px':'0'}} >
        <PeopleFlourishing
          isDeployedDone={true}
          comparison={comparison}
          primaryData={
            primaryData?.data?.[appliedFiltersPrimary.timeline] || []
          }
          secondaryData={
            !comparison
              ? []
              : secondaryData?.data?.[appliedFiltersSecondary?.timeline] || []
          }
          dimensionCatIds={
            secondaryData?.data?.["dimensionCatIds"] ||
            primaryData?.data?.["dimensionCatIds"]
          }
          primaryLabel={appliedFiltersPrimary?.["dataset"] === 'My Church' ? 'My Data' : appliedFiltersPrimary?.["dataset"]}
          secondaryLabel={appliedFiltersSecondary?.["dataset"] === 'My Church' ? 'My Data' : appliedFiltersSecondary?.["dataset"]}
          isChurch={isChurch}
        />
      </div>
      <div className="church-health-growthGoals">
        <GrowthGoals
          isDeployedDone={true}
          comparison={comparison}
          primaryData={primaryGrowthGoalsData || []}
          secondaryData={!comparison ? [] : secondaryGrowthGoalsData || []}
          dimensionCatIds={
            secondaryData?.data?.["dimensionCatIds"] ||
            primaryData?.data?.["dimensionCatIds"]
          }
        />
      </div>
      <div className="church-health__thriving">
        <ChurchThriving
          isDeployedDone={true}
          comparison={comparison}
          primaryData={
            primaryData?.data?.[appliedFiltersPrimary.timeline] || []
          }
          secondaryData={
            !comparison
              ? []
              : secondaryData?.data?.[appliedFiltersSecondary?.timeline] || []
          }
          dimensionCatIds={
            secondaryData?.data?.["dimensionCatIds"] ||
            primaryData?.data?.["dimensionCatIds"]
          }
          primaryLabel={appliedFiltersPrimary?.["dataset"] === 'My Church' ? 'My Data' : appliedFiltersPrimary?.["dataset"]}
          secondaryLabel={appliedFiltersSecondary?.["dataset"] === 'My Church' ? 'My Data' : appliedFiltersSecondary?.["dataset"]}
          isChurch={isChurch}
        />
      </div>

      <div className="church-health-Demographics">
        <Demographics
          isDeployedDone={true}
          primaryData={primaryDemographicsData || null}
          secondaryData={!comparison ? null : secondaryDemographicsData || null}
          comparison={comparison}
          appliedFiltersPrimary={appliedFiltersPrimary}
          appliedFiltersSecondary={appliedFiltersSecondary}
          primaryLabel={appliedFiltersPrimary?.["dataset"] === 'My Church' ? 'My Data' : appliedFiltersPrimary?.["dataset"]}
          secondaryLabel={appliedFiltersSecondary?.["dataset"] === 'My Church' ? 'My Data' : appliedFiltersSecondary?.["dataset"]}
          isChurch={isChurch}
        />
      </div>
    </div>

  );
};

export default ChurchHealth;
