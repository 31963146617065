import React, { useEffect, useRef, useState } from "react";
import ChurchHealthMobileCards from "./ChurchHealthMobileCards/ChurchHealthMobileCards";
import { Header } from "./Header/Header";
import MobileFilterCard from "../MobileFilterCard/MobileFilterCard";
import { MobileAIInsights } from "./MobileAIInsights/MobileAIInsights";
import RelationshipBadge from "../../Assests/Images/badge-heart.svg";
import PurposeBadge from "../../Assests/Images/badge-purpose.svg";
import ContentmentBadge from "../../Assests/Images/badge-contentment.svg";
import WellnessBadge from "../../Assests/Images/badge-health.svg";
import FinancesBadge from "../../Assests/Images/badge-finances.svg";
import SpiritualBadge from "../../Assests/Images/badge-spiritual.svg";
import CharacterBadge from "../../Assests/Images/badge-character.svg";
import arrowright from '../../Assests/Images/arrow-right-black-view.svg';
import ring from "../../Assests/Images/ring.svg"
import baby from "../../Assests/Images/baby.svg"
import heartCrack from "../../Assests/Images/heart-crack.svg"
import personCane from "../../Assests/Images/person-cane.svg"
import plusIcon from "../../Assests/Images/plus-icon.svg";

// Nurturing
import connectedCommunityIcon from "../../Assests/Images/ThrivingResults/connected-community-bold.svg"
import workshipExperienceIcon from "../../Assests/Images/ThrivingResults/workship-experience-bold.svg"
import prayerCultureIcon from "../../Assests/Images/ThrivingResults/prayer-culture-bold.svg"
import bibleCenterednessIcon from "../../Assests/Images/ThrivingResults/bible-centeredness-bold.svg"
import spiritualFormationIcon from "../../Assests/Images/ThrivingResults/spiritual-formation-bold.svg"
import trustedLeadersIcon from "../../Assests/Images/ThrivingResults/trusted-leaders-bold.svg"

// sending
import faithSharingIcon from "../../Assests/Images/ThrivingResults/faith-sharing-bold.svg"
import servingOthersIcon from "../../Assests/Images/ThrivingResults/serving-others-bold.svg"
import holisticStewardshipIcon from "../../Assests/Images/ThrivingResults/holistic-stewardship-bold.svg"
import leadershipDevelopmentIcon from "../../Assests/Images/ThrivingResults/leadership-development-bold.svg"
import socialImpactIcon from "../../Assests/Images/ThrivingResults/social-impact-bold.svg"

// leading
import futureFocusedIcon from "../../Assests/Images/ThrivingResults/future-focused-bold.svg"
import dataInformedIcon from "../../Assests/Images/ThrivingResults/data-informed-bold.svg"
import resourceStabilityIcon from "../../Assests/Images/ThrivingResults/resource-stability-bold.svg"
import teamHealthIcon from "../../Assests/Images/ThrivingResults/team-health-bold.svg"
import { useNavigate } from "react-router-dom";
import { AIChurchThrivingMobile } from "./AIChurchThrivingMobile/AIChurchThrivingMobile";
import { ShimmerDiv } from "shimmer-effects-react";

const chartDataTemplatepeopleFlourishing = [
  { name: "Relationships", variable: "cprelation", icon: RelationshipBadge, mydata: 32 },
  { name: "Purpose", variable: "cppurpose", icon: PurposeBadge, mydata: 34 },
  { name: "Spiritual", variable: "cpspiritual", icon: SpiritualBadge, mydata: 84 },
  { name: "Character", variable: "cpcharacter", icon: CharacterBadge, mydata: 27 },
  { name: "Finances", variable: "cpfinance", icon: FinancesBadge, mydata: 48 },
  { name: "Contentment", variable: "cpcontent", icon: ContentmentBadge, mydata: 63 },
  { name: "Health", variable: "cphealth", icon: WellnessBadge, mydata: 63 },
];
// Create chart data using the averages
const chartDataTemplateGrowthGoals = [
  { id: 1, name: "Relationships", variable: "cprelation", description: "Grow in the quality of my relationships.", icon: RelationshipBadge, },
  { id: 2, name: "Purpose", variable: "cppurpose", description: "Grow in the sense of meaning I find in daily activity.", icon: PurposeBadge, },
  { id: 3, name: "Spiritual", variable: "cpspiritual", description: "Grow in my spiritual vitality.", icon: SpiritualBadge, },
  { id: 4, name: "Health", variable: "cphealth", description: "Grow in my overall physical and mental health.", icon: WellnessBadge, },
  { id: 5, name: "Finances", variable: "cpfinance", description: "Grow in my financial stability.", icon: FinancesBadge, },
  { id: 6, name: "Character", variable: "cpcharacter", description: "Grow in my responsiveness to God and culture.", icon: CharacterBadge, },
  { id: 7, name: "Contentment", variable: "cpcontent", description: "Grow in my overall life happiness and satisfaction.", icon: ContentmentBadge, },
];

const chartDataTemplateChurchThriving = {
  "Nurturing": [
    { variable: "cpcommunity", name: "Connected Community", mydata: 32, icon: connectedCommunityIcon },
    { variable: "cpworship", name: "Worship Experience", mydata: 34, icon: workshipExperienceIcon },
    { variable: "cptrusted", name: "Trusted Leaders", mydata: 84, icon: trustedLeadersIcon },
    { variable: "cpbible", name: "Bible Centeredness", mydata: 27, icon: bibleCenterednessIcon },
    { variable: "cpformation", name: "Spiritual Formation", mydata: 48, icon: spiritualFormationIcon },
    { variable: "cpprayer", name: "Prayer Culture", mydata: 63, icon: prayerCultureIcon },

  ],
  "Sending": [
    { variable: "cpfaith", name: "Faith Sharing", mydata: 44, icon: faithSharingIcon },
    { variable: "cpserving", name: "Serving Others", mydata: 74, icon: servingOthersIcon },
    { variable: "cpsteward", name: "Holistic Stewardship", mydata: 34, icon: holisticStewardshipIcon },
    { variable: "cpleaddev", name: "Leadership Development", mydata: 84, icon: leadershipDevelopmentIcon },
    { variable: "cpimpact", name: "Social Impact", mydata: 31, icon: socialImpactIcon }
  ],
  "Leading": [
    { variable: "cpfuture", name: "Future Focused", mydata: 21, icon: futureFocusedIcon },
    { variable: "cpdata", name: "Data Informed", mydata: 45, icon: dataInformedIcon },
    { variable: "cpresource", name: "Resource Stability", mydata: 97, icon: resourceStabilityIcon },
    { variable: "cpteam", name: "Team Health", mydata: 10, icon: teamHealthIcon }
  ]
}

const chartDataTemplateDemographics = [
  { name: "Marriage", icon: ring, mydata: 45, nationalaverage: 78, variable: 'cpmarriage', description: "What percentage of congregants are married?" },
  { name: "Parenthood", icon: baby, mydata: 81, nationalaverage: 51, variable: 'cpparenthood', description: "What percentage of congregants are parents?" },
  { name: "Divorce", icon: heartCrack, mydata: 81, nationalaverage: 51, variable: 'cpdivorced', description: "What percentage of congregants are divorced?" },
  // { name: "Seniors", icon: personCane, mydata: 81, nationalaverage: 51, variable : 'cpdivorced', description: "What percentage of congregants are greater than 55 years old?" },
]

const ChurchHealthMobile = ({ comparison, isDeployedDone, peopleFlourishingprimaryData, peopleFlourishingsecondaryData, growthGoalsPrimaryData, growthGoalsSecondaryData, demographicsprimaryData, demographicssecondaryData, dimensionCatIds, appliedFiltersPrimary, setAppliedFiltersPrimary, appliedFiltersSecondary, setAppliedFiltersSecondary, setComparison, primaryLabel, secondaryLabel, isChurch }) => {
  const [activeTab, setActiveTab] = useState("Nurturing");
  const tabs = ["Nurturing", "Sending", "Leading"];
  const navigate = useNavigate();
  const [isiPhone, setIsiPhone] = useState(false);
  const [showLimitedTimeBanner, setShowLimitedTimeBanner] = useState(isDeployedDone)
  const [isLoading, setIsLoading] = useState(true);
  const [childDataReady, setChildDataReady] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/iPhone/i.test(userAgent)) {
      setIsiPhone(true);
    }
  }, []);

  const handleNavigation = () => {
    navigate("/comparative-demographic-details",
      {
        state: {
          from: 'Demographics',
          isDeployedDone: isDeployedDone,
          isComparisonBarChart: comparison,
        }
      }
    )
  }

  useEffect(() => {
    if (childDataReady) {
      setIsLoading(false);
    }
  }, [childDataReady]);


  console.log("isLoading" , isLoading)

  return (
    <>
      <Header setShowLimitedTimeBanner={setShowLimitedTimeBanner} showLimitedTimeBanner={showLimitedTimeBanner} isDeployedDone={isDeployedDone} />
      <div className={`church-health-mobile-container ${isiPhone ? "isiPhone" : ""}`} style={{ paddingTop: showLimitedTimeBanner ? '0' : '70px' }}>
        <div className="filter-container-mobile" style={{ position: showLimitedTimeBanner ? 'relative' : 'fixed', top: !showLimitedTimeBanner && "73px" }}>
          <MobileFilterCard
            filterType="leftfilter"
            appliedFilters={appliedFiltersPrimary}
            setAppliedFilters={setAppliedFiltersPrimary}
            isDeployedDone={isDeployedDone}
          />
          <div className='divider' />
          {
            comparison ?
              <MobileFilterCard
                filterType="rightfilter"
                isDeployedDone={isDeployedDone}
                appliedFilters={appliedFiltersSecondary || {}}
                setAppliedFilters={setAppliedFiltersSecondary}
                comparison={comparison}
                setComparison={(value) => {
                  setComparison(value);
                }}
              /> :
              <div className="add-comparison-container" onClick={() => { setComparison(true) }}>
                <img src={plusIcon} alt="plusIcon" />
                <p className="add-comparison-text">Add a comparison</p>
              </div>

          }
        </div>
        {isLoading &&
          <div className="shimmer-container-growthgoal" >
            <div className="shimmer-header-container" >
              <div>
                <div style={{ opacity: "50%", marginBottom: 10 }}>
                  <ShimmerDiv mode="light" width={232} height={20} rounded={1} />
                </div>
                <div style={{ opacity: "50%", marginBottom: 22 }}>
                  <ShimmerDiv mode="light" width={345} height={52} rounded={1} />
                </div>
              </div>
            </div>
            <div className="shimmer-cards">
              <div style={{ opacity: "50%" }}>
                <ShimmerDiv mode="light" width={343} height={351} rounded={1} />
              </div>
            </div>
            <div className="shimmer-aiinsight">
              <div style={{ opacity: "50%" }}>
                <ShimmerDiv mode="light" width={343} height={235} rounded={1} />
              </div>
            </div>
          </div>}

        <div className="mobile-people-flourishing-container" style={{ marginTop: showLimitedTimeBanner ? '-12px' : '106px', display: isLoading && "none" }}>
          <span className="mobile-people-flourishing-container__heading">
            People Flourishing
          </span>
          <span className="mobile-people-flourishing-container__description">
            7 dimensions of whole-person health. How are my people doing and
            where do they need support?
          </span>
          <ChurchHealthMobileCards
            charategry='PeopleFlourishing'
            chartDataTemplate={chartDataTemplatepeopleFlourishing}
            comparison={comparison}
            isDeployedDone={isDeployedDone}
            primaryData={peopleFlourishingprimaryData}
            secondaryData={peopleFlourishingsecondaryData}
            dimensionCatIds={dimensionCatIds}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            setChildDataReady={setChildDataReady}
          />
        </div>
        {isLoading === false &&
          <MobileAIInsights isChurch={isChurch} isDeployedDone={isDeployedDone} />
        }


        {isLoading &&
          <div className="shimmer-container-growthgoal" >
            <div className="shimmer-header-container" >
              <div>
                <div style={{ opacity: "50%", marginBottom: 10 }}>
                  <ShimmerDiv mode="light" width={173} height={20} rounded={1} />
                </div>
                <div style={{ opacity: "50%", marginBottom: 22 }}>
                  <ShimmerDiv mode="light" width={345} height={31} rounded={1} />
                </div>
              </div>
            </div>
            <div className="shimmer-cards">
              <div style={{ opacity: "50%" }}>
                <ShimmerDiv mode="light" width={343} height={398} rounded={1} />
              </div>
            </div>
          </div>}

        <div className="mobile-gowth-goals-container" style={{ display: isLoading ? 'none' : '' }}>
          <span className="mobile-gowth-goals-container__heading">
            Growth Goals
          </span>
          <span className="mobile-gowth-goals-container__description">
            See which dimension of flourishing your people want to grow in the most.
          </span>
          <ChurchHealthMobileCards
            charategry='GrowthGoals'
            comparison={comparison}
            isDeployedDone={isDeployedDone}
            chartDataTemplate={chartDataTemplateGrowthGoals}
            primaryData={growthGoalsPrimaryData}
            secondaryData={growthGoalsSecondaryData}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            setChildDataReady={setChildDataReady}
          />
        </div>

        {isLoading &&
          <div className="shimmer-container-growthgoal" >
            <div className="shimmer-header-container" >
              <div>
                <div style={{ opacity: "50%", marginBottom: 10 }}>
                  <ShimmerDiv mode="light" width={173} height={32} rounded={1} />
                </div>
                <div style={{ opacity: "50%", marginBottom: 22 }}>
                  <ShimmerDiv mode="light" width={345} height={52} rounded={1} />
                </div>
              </div>
              <div className="shimmer-tabs">
                <div style={{ opacity: "50%" }}>
                  <ShimmerDiv mode="light" width={108} height={34} rounded={1} />
                </div>
                <div style={{ opacity: "50%" }}>
                  <ShimmerDiv mode="light" width={108} height={34} rounded={1} />
                </div>
                <div style={{ opacity: "50%" }}>
                  <ShimmerDiv mode="light" width={108} height={34} rounded={1} />
                </div>
              </div>
            </div>
            <div className="shimmer-cards">
              <div style={{ opacity: "50%" }}>
                <ShimmerDiv mode="light" width={343} height={398} rounded={1} />
              </div>
            </div>
            <div className="shimmer-aiinsight">
              <div style={{ opacity: "50%" }}>
                <ShimmerDiv mode="light" width={343} height={235} rounded={1} />
              </div>
            </div>
          </div>}

        <div className="mobile-thriving-container" style={{ display: isLoading ? 'none' : '' }}>
          <span className="mobile-thriving-container__heading">
            Church Thriving
          </span>
          <span className="mobile-thriving-container__description">
            15 dimensions of <span>Church-specific</span> health metrics, broken into 3 buckets. What are the deeper drivers of my church health?
          </span>
          <div className="mobile-thriving-tab-container">
            {tabs.map((tab) => (
              <div
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`
                    ${activeTab === tab && "active"}`}
              >
                {tab}
              </div>
            ))}
          </div>
          <div>
            {/* ChurchThriving */}
            <ChurchHealthMobileCards
              charategry='ChurchThriving'
              comparison={comparison}
              activeCard={activeTab}
              isDeployedDone={isDeployedDone}
              chartDataTemplate={chartDataTemplateChurchThriving}
              primaryData={peopleFlourishingprimaryData}
              secondaryData={peopleFlourishingsecondaryData}
              dimensionCatIds={dimensionCatIds}
              primaryLabel={primaryLabel}
              secondaryLabel={secondaryLabel}
              setChildDataReady={setChildDataReady}
            />
           
          </div>
        </div>

        {isLoading === false &&  <AIChurchThrivingMobile isChurch={isChurch} isDeployedDone={isDeployedDone} />}

        {isLoading &&
          <div className="shimmer-container-growthgoal" >
            <div className="shimmer-header-container" >
              <div>
                <div style={{ opacity: "50%", marginBottom: 10 }}>
                  <ShimmerDiv mode="light" width={187} height={20} rounded={1} />
                </div>
                <div style={{ opacity: "50%", marginBottom: 22 }}>
                  <ShimmerDiv mode="light" width={345} height={31} rounded={1} />
                </div>
                <div style={{ opacity: "50%", marginBottom: 22 }}>
                  <ShimmerDiv mode="light" width={136} height={48} rounded={1} />
                </div>
              </div>
            </div>
            <div className="shimmer-cards">
              <div style={{ opacity: "50%" }}>
                <ShimmerDiv mode="light" width={343} height={398} rounded={1} />
              </div>
            </div>
          </div>}
        <div className="mobile-demographics-container" style={{ display: isLoading ? 'none' : "" }} >
          <span className="mobile-demographics-container__heading">
            Demographics
          </span>
          <span className="mobile-demographics-container__description">
            Learn more about the characteristics of your community.
          </span>
          <div className="viewcta" onClick={handleNavigation}>
            <button className="cta-viewall" >View all <img src={arrowright} alt="" style={{ width: "16px" }} /></button>
          </div>
          {/* Demographics */}
          <ChurchHealthMobileCards
            charategry='Demographics'
            comparison={comparison}
            isDeployedDone={isDeployedDone}
            chartDataTemplate={chartDataTemplateDemographics}
            primaryData={demographicsprimaryData}
            secondaryData={demographicssecondaryData}
            primaryLabel={primaryLabel}
            secondaryLabel={secondaryLabel}
            setChildDataReady={setChildDataReady}
          />
        </div>

      </div>
    </>
  );
};

export default ChurchHealthMobile;
