import React, { startTransition, useContext, useEffect, useState, useRef } from 'react';
import { Typography, FormControlLabel, Radio, RadioGroup, FormControl, Button, Chip, Box, TextareaAutosize, Grid, Tabs, Tab, colors } from '@mui/material';
import AssessmentHeader from './AssessmentHeader';
import Share from '../../components/Assessments/Share/Share';
import Result from '../../components/Assessments/Result/Result';
import Partners from '../../components/Assessments/Partners/Partners';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LinkIcon from '@mui/icons-material/Link';
import CodeIcon from '@mui/icons-material/Code';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import VideocamIcon from '@mui/icons-material/Videocam';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import Alert from '../../Assests/Images/alert.svg';
import { useNavigate } from "react-router-dom";
import planningcenter from '../../Assests/Images/PannningCenter.png'
import gloologo from '../../Assests/Images/gloologo.png'
import shareIcon from '../../Assests/Images/shareIcon2.svg'
import shareActiveIcon from '../../Assests/Images/shareActiveIcon2.svg'
import configIcon from '../../Assests/Images/configure.svg'
import configActiveIcon from '../../Assests/Images/configure-active.svg'
import resultsIcon from '../../Assests/Images/resultsIcon2.svg'
import resultsActiveIcon from '../../Assests/Images/resultIconactive2.svg'
import checkIcon from '../../Assests/Images/checkBox.svg';
import checkedIcon from '../../Assests/Images/checkedBox.svg';

import { useLocation, useSearchParams, useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";

import Calendar from '../../components/Assessments/Calender/Calendar';
import Time from '../../components/Assessments/Time/Time';
import AuthContext from '../../store/AuthContext';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import GlooLogoOption from "../../Assests/Images/gloo-logo-option.svg"
import PlanningCenter from "../../Assests/Images/planning-center.svg"
import { toast } from 'react-toastify';
import { useCloseDeploymentMutation } from '../../hooks/useQueryAPI';



const Assessments = () => {

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate()
  const { windowHeight } = useWindowDimensions();
  const { state } = useLocation();
  const { deployId } = useParams()

  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(0);
  const [deployment, setDeployment] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false)
  const getOrgIdFromUrl = () => {
    return authCtx.orgId;
    // console.log(authCtx)
    // const params = new URLSearchParams(location.search);
    // return params.get("orgId") || "186";
  };

  const orgId = getOrgIdFromUrl();

  const [ongoing, setOngoing] = useState(false);

  // const [dataCollection, setDataCollection] = useState('Anonymous');

  const [sync, setSync] = useState(false);

  const [selectedPartners, setSelectedPartners] = useState(['Gloo', 'Barna']);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [text, setText] = useState('');
  const maxChars = 1224;
  const [selectedStartDate, setSelectedStartDate] = useState(deployment.startDate ? new Date(deployment.startDate) : null);
  const [selectedStartTime, setSelectedStartTime] = useState(deployment.startTime || null);
  const [selectedEndDate, setSelectedEndDate] = useState(deployment.endDate ? new Date(deployment.endDate) : null);
  const [selectedEndTime, setSelectedEndTime] = useState(deployment.startTime || null);
  const [deploymentName, setDeploymentName] = useState(deployment?.title || "");
  const [pastorName, setPastorName] = useState("");
  const [orgName, setOrgName] = useState(deployment?.organisationName)
  const [inputValue, setInputValue] = useState('');
  const [fullUrl, setFullUrl] = useState('');
  const [creatingTxt, setIsCreatingTxt] = useState("Create");
  const [savingTxt, setIsSavingTxt] = useState("Save");
  const [originalValues, setOriginalValues] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [configureDone, setConfigureDone] = useState(false);
  const [namePhoneOptIn, setnamePhoneOptIn] = useState('CollectName');
  const closeDeploymentMutation = useCloseDeploymentMutation(authCtx.token);
  const [errors, setErrors] = useState({
    deploymentName: "",
    startDateTime: "",
    endDateTime: "",
    pastorName: "",
    orgName: "",
    message: ""
  })
  const [file, setFile] = useState(null)
  const [imgUrl, setImageUrl] = useState(null)
  const [imageName, setImageName] = useState('')
  const [imageSize, setImageSize] = useState(0)
  //Regex
  const nameRegex = /^[a-zA-Z\s\W]*$/;
  const fileTypesRegex = /^image\/(jpeg|png)$/;
  const orgNameRegex = /^[a-zA-Z\s]*$/
  // useEffect(() => {
  //   window.parent.postMessage({ event: 'sotcEmbed:dimensions', name: 'sotcEmbed', height: windowHeight }, 'https://app.dev.gloo.us')
  // }, [windowHeight]);

  useEffect(() => {
    if (selectedStartDate && selectedStartTime && errors.startDateTime !== "") {
      setErrors(prevObj => ({ ...prevObj, startDateTime: "" }))
    }
  }, [selectedStartDate, selectedStartTime])

  useEffect(() => {
    if (selectedEndDate && selectedEndTime && errors.endDateTime !== "") {
      setErrors(prevObj => ({ ...prevObj, endDateTime: "" }))
    }
  }, [selectedEndDate, selectedEndTime])

  useEffect(() => {
    if (ongoing) {
      setErrors(prevObj => ({ ...prevObj, endDateTime: "", startDateTime: "" }))
    }
  }, [ongoing])

  useEffect(() => {
    if (deploymentName && deploymentName !== "" && errors.deploymentName !== "") {
      setErrors(prevObj => ({ ...prevObj, deploymentName: "" }))
    }
  }, [deploymentName])

  useEffect(() => {
    if (pastorName && pastorName !== "" && errors.pastorName !== "") {
      setErrors(prevObj => ({ ...prevObj, pastorName: "" }))
    }
  }, [pastorName])

  useEffect(() => {
    if (orgName && orgName !== "" && errors.orgName !== "") {
      setErrors(prevObj => ({ ...prevObj, orgName: "" }))
    }
  }, [orgName])

  useEffect(() => {
    if (text && text !== "" && errors.message !== "") {
      setErrors(prevObj => ({ ...prevObj, message: "" }))
    }
  }, [text])

  useEffect(() => {
    if (file) {
      setImageUrl(URL.createObjectURL(file));
      setImageName(file?.name)
      setImageSize(file?.size)
    }
  }, [file])

  useEffect(() => {
    if (searchParams && searchParams?.get('tab')) {
      let tabName = searchParams?.get('tab');
      switch (tabName) {
        case 'results': setValue(2); break;
        case 'share': setValue(1); break;
        default: setValue(0);
      }
    }
  }, [searchParams])

  const handleChangeFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]; // Get the selected file
      if (file) {
        const fileType = file.type; // Get the file's MIME type
        const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"]; // Allowed types

        // Check if the file type is valid
        if (!validImageTypes.includes(fileType)) {
          setErrors((prev) => ({
            ...prev,
            image: "Only image files (JPEG, PNG, GIF, WEBP) are allowed.",
          }));
          return; // Stop further processing
        }

        // Check if the file size is within the limit
        const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
        if (fileSizeInMB > 15) {
          setErrors((prev) => ({
            ...prev,
            image: "File size should be less than 2MB.",
          }));
        } else {
          setErrors((prev) => ({ ...prev, image: "" }));
          setFile(file);
        }
      }
    }
  }

  const handleRemoveImage = () => {
    setFile(null);
    setImageUrl(null);
  }

  const handleModalClose = () => {
    setOpenPopup(false)
  }

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOngoingToggle = () => {
    setOngoing(!ongoing);
  };

  const handleSyncToggle = () => {
    setSync(!sync);
  };

  const handleDeletePartner = (partner) => {
    setSelectedPartners((partners) => partners.filter((p) => p !== partner));
  };

  const handleTodayClick = () => {
    setSelectedStartDate(new Date());
    setErrors(prev => ({ ...prev, startDateTime: "" }));
  };
  const handleTodayClickEnd = () => {
    setSelectedEndDate(new Date());
    setErrors(prev => ({ ...prev, endDateTime: "" }));
  };

  const handleClearClick = () => {
    setSelectedStartDate(null);
  };
  const handleClearClickEnd = () => {
    setSelectedEndDate(null);
  };

  const isValidDateRange = (startDate, startTime, endDate, endTime) => {
    const startDateTime = new Date(startDate);
    const [startHour, startMinute] = startTime ? startTime.split(':').map(Number) : [0, 0];
    startDateTime.setHours(startHour, startMinute, 0, 0);

    const endDateTime = new Date(endDate);
    const [endHour, endMinute] = endTime ? endTime.split(':').map(Number) : [0, 0];
    endDateTime.setHours(endHour, endMinute, 0, 0);

    return startDateTime <= endDateTime;
  }

  const deployAssessment = async () => {

    if (validateForm()) {
      let properties = [
        {
          property: "type", value: ongoing ? 'Ongoing' : "Fixed"
        },
        {
          property: "anonymous", value: true
        },
        {
          property: "startDate", value: selectedStartDate
        },
        {
          property: "startTime", value: selectedStartTime
        },
      ];


      if (!ongoing) {
        properties = [...properties,
        {
          property: "endDate", value: selectedEndDate
        },
        {
          property: "endTime", value: selectedEndTime
        },
        ]
      }

      if (pastorName && pastorName !== '') {
        properties = [...properties, { property: "pastorName", value: pastorName }, { property: "organisationName", value: orgName }, { property: "message", value: text }]

        if (file) {
          try {
            const res = await uploadImage(file);
            properties.push(
              { property: "image", value: res.imagePath },
              { property: "imageName", value: imageName },
              { property: "imageSize", value: imageSize }
            );
          } catch (err) {
            console.error("Image upload failed", err);
            setIsSavingTxt("Error in Uploading Image");
            return;
          }
        }
      }

      setIsCreatingTxt("Creating...");


      const payload = {
        title: deploymentName,
        properties: [...properties]
      };

      console.log(payload);

      const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/deployments`;

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload)
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success") {
          setIsCreatingTxt("Create");
          return data;
        } else {
          console.error("Failed to fetch insights data");
          return null;
        }
      } catch (err) {
        console.error("Error fetching insights data...", err);
        throw err;
      }
    } else {
      console.log(errors);
    }

  }

  const updateAssessment = async () => {

    if (validateForm()) {
      let properties = [
        {
          property: "type", value: ongoing ? 'Ongoing' : "Fixed"
        },
        {
          property: "anonymous", value: true
        },
        {
          property: "startDate", value: selectedStartDate
        },
        {
          property: "startTime", value: selectedStartTime
        },
      ];


      if (!ongoing) {
        properties = [...properties,
        {
          property: "endDate", value: selectedEndDate
        },
        {
          property: "endTime", value: selectedEndTime
        },
        ]
      }

      if (pastorName && pastorName !== '') {
        properties = [...properties, { property: "pastorName", value: pastorName }, { property: "organisationName", value: orgName }, { property: "message", value: text }]
      }

      if (file) {
        try {
          const res = await uploadImage(file);
          properties.push(
            { property: "image", value: res.imagePath },
            { property: "imageName", value: imageName },
            { property: "imageSize", value: imageSize }
          );
        } catch (err) {
          console.error("Image upload failed", err);
          setIsSavingTxt("Error in Uploading Image");
          return;
        }
      }
      setIsSavingTxt("Saving...");

      const payload = {
        id: deployment?.id,
        title: deploymentName,
        properties: [...properties]
      };

      console.log(payload);

      const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/deployments`;

      try {
        const response = await fetch(apiUrl, {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload)
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success") {
          setIsSavingTxt("Save");
          return data;
        } else {
          console.error("Failed to fetch insights data");
          return null;
        }
      } catch (err) {
        console.error("Error fetching insights data...", err);
        throw err;
      }
    }
    else {
      console.log(errors);
    }

  }

  const validateForm = () => {
    let isValid = true;
    if (!Boolean(deploymentName)) {
      setErrors(prevObj => ({ ...prevObj, deploymentName: 'Please enter the deployment name.' }));
      isValid = false;
    } else if (!orgNameRegex.test(deploymentName.trim())) {
      setErrors(prevObj => ({ ...prevObj, deploymentName: "It must contain at least 3 letters and no numbers or special characters" }));
      isValid = false;
    }
    if (!Boolean(selectedStartDate) || !Boolean(selectedStartTime)) {
      setErrors(prevObj => ({ ...prevObj, startDateTime: 'Please select the start date and time.' }));
      isValid = false;
    }
    if (!ongoing && (!Boolean(selectedEndDate) || !Boolean(selectedEndTime))) {
      setErrors(prevObj => ({ ...prevObj, endDateTime: 'Please select the end date and time.' }));
      isValid = false;
    }
    // if ((!pastorName || pastorName === '') && (file || (orgName && orgName !== '') || (text && text !== ''))) {
    //   setErrors(prevObj => ({ ...prevObj, pastorName: 'Please enter pastor name.' }));
    //   isValid = false;
    // }
    // if ((!orgName || orgName === '') && (file || (pastorName && pastorName !== '') || (text && text !== ''))) {
    //   setErrors(prevObj => ({ ...prevObj, orgName: 'Please enter organization name.' }));
    //   isValid = false;
    // }
    // if ((!text || text === '') && (file || (pastorName && pastorName !== '') || (orgName && orgName !== ''))) {
    //   setErrors(prevObj => ({ ...prevObj, message: 'Please enter the message.' }));
    //   isValid = false;
    // }

    if (!pastorName || pastorName.trim() === "") {
      setErrors(prevObj => ({ ...prevObj, pastorName: "Please enter the name of the pastor(s)." }));
      isValid = false;
    } else if (!nameRegex.test(pastorName.trim())) {
      setErrors(prevObj => ({
        ...prevObj,
        pastorName: "It must contain at least 3 letters and no numbers."
      }));
      isValid = false;
    }

    if (!orgName || orgName.trim() === "") {
      setErrors(prevObj => ({ ...prevObj, orgName: "Please enter the name of the organization." }));
      isValid = false;
    } else if (!orgNameRegex.test(orgName.trim())) {
      setErrors(prevObj => ({
        ...prevObj,
        orgName: "It must contain at least 3 letters and no numbers or special characters."
      }));
      isValid = false;
    }
    if (!text || text.trim() === "") {
      setErrors(prevObj => ({ ...prevObj, message: "Please enter your custom message." }));
      isValid = false;
    } else if (text.length < 30) {
      setErrors(prevObj => ({ ...prevObj, message: "Custom message must be at least 30 characters long." }));
      isValid = false;
    }

    if (file) {
      const maxFileSize = 15 * 1024 * 1024; // 2MB
      if (file.size > maxFileSize) {
        setErrors(prevObj => ({ ...prevObj, file: "Uploaded image must be smaller than 2MB." }));
        isValid = false;
      } else if (!fileTypesRegex.test(file.type)) {
        setErrors(prevObj => ({ ...prevObj, file: "Only PNG and JPEG images are allowed." }));
        isValid = false;
      }
    }
    if (!ongoing && selectedStartDate && selectedStartTime && selectedEndDate && selectedEndTime) {
      if (!isValidDateRange(selectedStartDate, selectedStartTime, selectedEndDate, selectedEndTime)) {
        setErrors(prevObj => ({ ...prevObj, endDateTime: selectedStartDate > selectedEndDate ? 'Please select a valid date' : 'Please select a valid time' }));
        isValid = false;
      }
    }
    console.log(isValid);
    return isValid;
  }

  useEffect(() => {

    // const fetchDeployment = async (deployId) => {
    //   try {
    //     const response = await fetch(
    //       `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${authCtx.orgId}/deployments/${deployId}`,
    //       { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + authCtx.token }, }
    //     );
    //     const res = await response.json();
    //     if (res.status === "success") {
    //       return res.data;
    //     } else {
    //       console.log("Error in fetching deployment details");
    //       throw new Error("Failed in fetching deployment");
    //     }
    //   } catch (err) {
    //     console.error("Failed to fetch deployment, please try again", err);
    //     throw new Error("Failed in fetching deployment");
    //   }
    // };

    const fetchDeployment = async (_deployId) => {
      let deployId = Number(_deployId);
      let deployment = localStorage.getItem('deployment');
      if (deployment) deployment = JSON.parse(deployment);
      if (deployment?.id === deployId) {
        return deployment;
      }
      else {
        localStorage.removeItem('deployment');
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${authCtx.orgId}/deployments/${deployId}`,
            { method: "GET", mode: "cors", headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + authCtx.token }, }
          );
          const res = await response.json();
          if (res.status === "success") {
            localStorage.setItem('deployment', JSON.stringify(res.data));
            return res.data;
          } else {
            console.log("Error in fetching deployment details");
            throw new Error("Failed in fetching deployment");
          }
        } catch (err) {
          console.error("Failed to fetch deployment, please try again", err);
          throw new Error("Failed in fetching deployment");
        }
      }
    }



    setLoading(true);
    fetchDeployment(deployId).then((deployment) => {
      setDeployment(deployment);
      setConfigureDone(Object.keys(deployment || {}).length > 0);
      setDeploymentName(deployment.title || "");
      if (deployment.startDate) {
        setSelectedStartDate(new Date(deployment.startDate))
        setSelectedStartTime(deployment.startTime)
        setSelectedEndDate(new Date(deployment.endDate))
        setSelectedEndTime(deployment.endTime)
      }
      // setDataCollection(deployment.anonymous == '1' ? "Anonymous" : 'name-and-phone')
      setPastorName(deployment.pastorName || "")
      setOrgName(deployment.organisationName || "")
      setText(deployment.message || "")
      setOngoing(deployment.type === "Ongoing")
      if (deployment.image) {
        // setFile(deployment.image)
        setImageUrl(deployment.image)
        setImageName(deployment.imageName || '')
        setImageSize(deployment.imageSize || 0)
      }
      if (deployment) {
        const initialValues = {
          deploymentName: deployment.title || "",
          selectedStartDate: deployment.startDate ? new Date(deployment.startDate) : null,
          selectedStartTime: deployment.startTime || null,
          selectedEndDate: deployment.endDate ? new Date(deployment.endDate) : null,
          selectedEndTime: deployment.endTime || null,
          // dataCollection: deployment.anonymous === "1" ? "Anonymous" : "name-and-phone",
          pastorName: deployment.pastorName || "",
          orgName: deployment.organisationName || "",
          text: deployment.message || "",
          ongoing: deployment.type === "Ongoing",
          imgUrl: deployment.image || "",
          imageName: deployment.imageName || "",
          imageSize: deployment.imageSize || 0,
        };
        setOriginalValues(initialValues);
      }
    }).catch((err) => {
      console.log({ err });
    }).finally(() => {
      setLoading(false);
    })
  }, [deployId, authCtx.token])

  useEffect(() => {
    if (originalValues) {
      const hasChanged =
        deploymentName !== originalValues.deploymentName ||
        selectedStartDate?.getTime() !== originalValues.selectedStartDate?.getTime() ||
        selectedStartTime !== originalValues.selectedStartTime ||
        selectedEndDate?.getTime() !== originalValues.selectedEndDate?.getTime() ||
        selectedEndTime !== originalValues.selectedEndTime ||
        // dataCollection !== originalValues.dataCollection ||
        pastorName !== originalValues.pastorName ||
        orgName !== originalValues.orgName ||
        text !== originalValues.text ||
        ongoing !== originalValues.ongoing ||
        imageName !== originalValues.imageName ||
        imageSize !== originalValues.imageSize;

      setIsChanged(hasChanged);
    }
    console.log(deploymentName)
    console.log("isChanged :", isChanged)
  }, [deploymentName, selectedStartDate, selectedStartTime, selectedEndDate, selectedEndTime, pastorName, orgName, text, ongoing, imgUrl, imageName, imageSize, originalValues,]);


  useEffect(() => {
    if (state?.from === "flourishing") {
      setValue(2);
    }
  }, [state?.from])


  async function uploadImage(file) {
    let uploadUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/${orgId}/upload`;
    try {
      if (!file || !(file instanceof File)) {
        throw new Error("Invalid file provided");
      }

      const validFileTypes = ["image/jpeg", "image/png"];
      const maxFileSize = 15 * 1024 * 1024; // 2MB

      if (!validFileTypes.includes(file.type)) {
        throw new Error("Unsupported file type. Please upload a PNG or JPEG image.");
      }

      if (file.size > maxFileSize) {
        throw new Error("File size exceeds the 5MB limit.");
      }

      // Create a FormData object to append the file
      const formData = new FormData();
      formData.append("image", file); // 'image' is the key; adjust based on server requirements

      // Send the file using the fetch API
      const response = await fetch(uploadUrl, {
        method: "POST",
        body: formData, // Automatically sets Content-Type to multipart/form-data
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }

      // Parse and return the response
      const result = await response.json();
      return result; // Assuming server responds with a JSON object
    } catch (error) {
      console.error("Image upload failed:", error);
      throw error; // Rethrow the error to handle it where this function is called
    }
  }

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setInputValue(userInput);

    // Always prepend https://survey.church/ to the input
    const completeUrl = `https://survey.church/${userInput}`;
    setFullUrl(completeUrl);
  };

  useEffect(() => {
    if (!ongoing) {
      setSelectedEndDate(deployment.endDate ? new Date(deployment.endDate) : null);
      setSelectedEndTime(deployment.endTime || null);
      setErrors((prev) => ({ ...prev, endDateTime: "" }));
    }
  }, [ongoing, deployment]);

  useEffect(() => {
    if (!isValidDateRange(selectedStartDate, selectedStartTime, selectedEndDate, selectedEndTime)) {
      setSelectedEndDate(null);
      setSelectedEndTime(null);
    }
  }, [selectedStartDate, selectedStartTime]);

  const closeDeployment = async (deployId) => {
    try {
      const payload = {
        id: deployId,
        title: deployment.title,
        properties: [
          { property: "type", value: "Fixed" },
          { property: "endDate", value: deployment?.endDate}, 
        ]
      };

      const closeDeployment = await closeDeploymentMutation.mutateAsync({ orgId:orgId, payload });
      if(closeDeployment.status === "success"){
        setOpenPopup(null);
        toast.success("Deployment closed successfully");
      }
      else{
        toast.error("Failed to close deployment. Please try again.");
      }
    } catch (error) {
      console.error("Error closing deployment:", error);
      toast.error("Failed to close deployment. Please try again.");
    }
  }

  return isLoading ? (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  ) : (
    <div className="assessment-container">
      <AssessmentHeader
        link={value !== 0}
        deploymentName={deploymentName}
        setDeploymentName={setDeploymentName}
        deployAssessment={deployAssessment}
        newDeployment={deployId ? false : true}
        updateAssessment={updateAssessment}
        savingTxt={savingTxt}
        creatingTxt={creatingTxt}
        isChangedInForm={isChanged}
      />
      {errors.deploymentName && (
        <div className="error-name">{errors.deploymentName}</div>
      )}
      <div className="tab">
        <Box>
          <Tabs value={value} onChange={handleChange} className="tab-name">
            <Tab
              icon={
                value === 0 ? (
                  <img src={configActiveIcon} />
                ) : (
                  <img src={configIcon} />
                )
              }
              label="Configure"
            />
            { deployment && (
              <Tab
                icon={
                  value === 1 ? (
                    <img src={shareActiveIcon} />
                  ) : (
                    <img src={shareIcon} />
                  )
                }
                label="Share"
                disabled={!configureDone}
              />
            )}
            {deployment && (
              <Tab
                icon={
                  value === 2 ? (
                    <img src={resultsActiveIcon} />
                  ) : (
                    <img src={resultsIcon} />
                  )
                }
                label="Results"
                sx={{ paddingRight: "35px" }}
                disabled={!configureDone}
              />
            )}
            {Object.keys(deployment || {}).length > 0 &&
              deployment.assessment !== "" && (
                <div className="result-count">
                  {deployment?.respondents === "No"
                    ? 0
                    : deployment?.respondents}
                </div>
              )}
          </Tabs>

          {value === 0 && (
            <div className="share-container">
              <Box p={3}>
                {/* uncomment it when backend working. */}
                {/* <div className="link share bottom-line">
                  <div className="left-share">
                    <div className="heaping-share">
                      <p className='headtext'>Configure URL</p>
                    </div>
                    <p className='headSubtext' style={{ width: "189px" }}>Add unique text to customize your deployment link.</p>
                  </div>
                  <div className="right-share">
                    <div className='urltext'>URL:</div>
                    <div className='url-input-container'>
                      <span className='https-text'>https://survey.church/</span>
                      <input type="text" className='url-input' value={inputValue} onChange={handleInputChange} />
                    </div>
                  </div>
                </div> */}
                <div className="link share bottom-line">
                  <div className="left-share">
                    <div className="heaping-share">
                      <p className="headtext">Availability</p>
                    </div>
                    <p className="headSubtext">
                      Decide when your assessment is open for people to take.
                    </p>
                  </div>
                  <div className="right-share">
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: "center" }}
                    >
                      <Typography className="switchtext">Ongoing</Typography>
                      <AntSwitch
                        inputProps={{ "aria-label": "ant design" }}
                        checked={!ongoing}
                        onChange={(e) => setOngoing(!e.target.checked)}
                      />
                      <Typography className="switchtext">Range</Typography>
                    </Stack>

                    <div className="date-container">
                      <div className="startdate">
                        <p className="lable">Open assessment on:</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            gap={2}
                            alignItems="center"
                            marginRight={-3}
                          >
                            <Calendar
                              selectedDate={selectedStartDate || null}
                              handleTodayClick={handleTodayClick}
                              handleClearClick={handleClearClick}
                              setSelectedDate={setSelectedStartDate}
                              error={errors.startDateTime}
                              setError={(msg) =>
                                setErrors((prev) => ({
                                  ...prev,
                                  startDateTime: msg,
                                }))
                              }
                            />
                            <Time
                              selectedTime={selectedStartTime || null}
                              setSelectedTime={setSelectedStartTime}
                            />
                          </Box>
                        </LocalizationProvider>
                        {errors.startDateTime && (
                          <p className="error-date">{errors.startDateTime}</p>
                        )}
                      </div>
                      {!ongoing && (
                        <div className="enddate">
                          <p className="lable">Close assessment on:</p>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              gap={2}
                              alignItems="center"
                              marginRight={-3}
                            >
                              <Calendar
                                selectedDate={selectedEndDate || null}
                                handleTodayClick={handleTodayClickEnd}
                                handleClearClick={handleClearClickEnd}
                                setSelectedDate={setSelectedEndDate}
                                error={errors.endDateTime}
                                setError={(msg) =>
                                  setErrors((prev) => ({
                                    ...prev,
                                    endDateTime: msg,
                                  }))
                                }
                                minSelectableDate={selectedStartDate}
                              />
                              <Time
                                selectedTime={selectedEndTime || null}
                                setSelectedTime={setSelectedEndTime}
                                minTime={
                                  selectedStartDate &&
                                    selectedEndDate &&
                                    selectedStartDate.getDate() ===
                                    selectedEndDate.getDate()
                                    ? selectedStartTime
                                    : null
                                }
                              />
                            </Box>
                          </LocalizationProvider>
                          {errors.endDateTime && (
                            <p className="error-date">{errors.endDateTime}</p>
                          )}
                        </div>
                      )}
                      {ongoing && (
                        <Button
                          className="cta-close"
                          variant="outlined"
                          component="label"
                          sx={{ mt: 2 }}
                          onClick={() => setOpenPopup("closeDeployment")}
                        >
                          Close assessment
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="link share bottom-line">
                  <div className="left-share">
                    <div className="heaping-share">
                      <p className="headtext">Data Collection</p>
                    </div>
                    <p>
                      Select how you want your respondents to take the
                      assessment.
                    </p>
                  </div>
                  <div className="right-share">
                    <FormControl>
                      <div className="radio-container"> */}
                        {/* <RadioGroup value={dataCollection} onChange={(e) => setDataCollection(e.target.value)} >
                          <div className="anonymous">
                            <FormControlLabel value="Anonymous" control={<Radio className="custom-radio" />} label="Anonymous" className="MuiFormControlLabel-root" />
                            <p className='anonymous-note'>Keep all responses anonymous</p>
                          </div>
                          <div className="collect-name-and-phone">
                            <FormControlLabel value="Name & phone number opt-in" control={<Radio className='custom-label' />} label="Name & phone number opt-in" className=" collect-name" />
                            <p className='collect-note'>Give respondents the option to share their name and phone number with you.</p>
                          </div>
                        </RadioGroup> */}


                        {/* <div
                          className="collection-option"
                          onClick={() => {
                            setDataCollection("name-and-phone");
                          }}
                        >
                          <img
                            src={
                              dataCollection === "name-and-phone"
                                ? checkedIcon
                                : checkIcon
                            }
                            alt=""
                            className="radio-button"
                          />
                          <div className="text">
                            <p className="option">Name & phone number opt-in</p>
                            <p className="subtext">
                              Give respondents the option to share their name
                              and phone number with you.
                            </p>
                          </div>
                        </div> */}


                        {/* <div className='name-collection-container'>
                          <div
                            className="collection-option"
                            onClick={() => setnamePhoneOptIn('Anonymous')}
                          >
                            <img
                              src={namePhoneOptIn === 'Anonymous' ? checkedIcon : checkIcon}
                              alt=""
                              className="radio-button"
                            />
                            <div className="text">
                              <p className="option" >Anonymous</p>
                              <p className='description'>Keep all responses anonymous.</p>
                            </div>
                          </div>
                          <div
                            className="collection-option"
                            onClick={() => setnamePhoneOptIn('Name & phone number opt-in')}
                          >
                            <img
                              src={namePhoneOptIn === 'Name & phone number opt-in' ? checkedIcon : checkIcon}
                              alt=""
                              className="radio-button"
                            />
                            <div className="text">
                              <p className="option" >Name & phone number opt-in</p>
                              <p className='description'>Give respondents the option to<br></br> share their name and phone<br></br> number with you.</p>
                            </div>
                          </div>
                        </div> */}

                        {/* <Button className="gloo"> <img src={gloologo} alt="" /> </Button> */}
                      {/* </div>
                    </FormControl> */}
                    {/* <div>
                      <div className='collection'>
                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                          <Typography>Don’t Sync</Typography>
                          <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                          <Typography>Sync</Typography>
                        </Stack>
                        <img src={planningcenter} alt="planningcenter" className='planningcenter' />
                        <Button className='gloo'><img src={gloologo} alt="" /></Button>
                      </div>
                    </div> */}
                  {/* </div>
                </div> */}

                <div className="link share bottom-line">
                  <div className="left-share">
                    <div className="heaping-share">
                      <p className="headtext">Customization</p>
                    </div>
                    <p>
                      Write a custom message to greet your respondents before
                      they start the assessment.
                    </p>
                  </div>
                  <div className="right-share">
                    {/* <div className="namebox">
                      <div>
                        <p>From Name</p>
                        <input id="text" name="text" type="text" placeholder="Pastor John" className="input" />
                      </div>
                      <div>
                        <p>From Organization</p>
                        <input id="text" name="text" type="text" placeholder="Life Church" className="input" />
                      </div>
                    </div> */}
                    <div className="customtextbox">
                      <div className="user-inputs">
                        <div className="from-name-input">
                          <p className="from-name">From Name</p>
                          <TextField
                            placeholder="Pastor John"
                            variant="outlined"
                            name="fromName"
                            className="user-inputs"
                            onChange={(e) => setPastorName(e.target.value)}
                            value={pastorName}
                            error={errors.pastorName}
                          />
                          {errors.pastorName && (
                            <p className="error-text">{errors.pastorName}</p>
                          )}
                        </div>

                        <div className="from-organisation-input">
                          <p className="from-organisation">From Organization</p>
                          <TextField
                            placeholder="Life Church"
                            variant="outlined"
                            name="fromOrganisation"
                            className="user-inputs"
                            onChange={(e) => setOrgName(e.target.value)}
                            value={orgName}
                            error={errors.orgName}
                          />
                          {errors.orgName && (
                            <p className="error-text">{errors.orgName}</p>
                          )}
                        </div>
                      </div>
                      <p>Custom Message to Respondents</p>
                      <div
                        className={`text-box-container ${errors.message ? "error" : ""
                          }`}
                      >
                        <div className="text-box">
                          <div className="character-count">
                            {text.length}/{maxChars}
                          </div>
                          <textarea
                            value={text}
                            variant="outlined"
                            onChange={handleTextChange}
                            error={errors.message}
                            placeholder="Write text here..."
                            maxLength={maxChars}
                          />
                        </div>
                        {/* <div className="toolbar">
                          <InsertEmoticonIcon className="icon" />
                          <LinkIcon className="icon" />
                          <CodeIcon className="icon" />
                          <InsertPhotoIcon className="icon" />
                          <VideocamIcon className="icon" />
                        </div> */}
                      </div>
                      {errors.message && (
                        <p className="error-text">{errors.message}</p>
                      )}
                      {imgUrl ? (
                        <div className="image-uploaded-section">
                          <img src={imgUrl} alt="" />
                          <div className="file-details">
                            <h1>{imageName}</h1>
                            <h2>{((imageSize || 0) / 1024).toFixed(2)} Kb</h2>
                          </div>
                          <div
                            className="remove-image-btn"
                            onClick={handleRemoveImage}
                          >
                            Remove image
                          </div>
                        </div>
                      ) : (
                        <Button
                          className="cta-add"
                          variant="outlined"
                          component="label"
                          sx={{ mt: 2 }}
                        >
                          + Add a photo/logo
                          <input
                            type="file"
                            hidden
                            onChange={handleChangeFile}
                          />
                        </Button>
                      )}
                      {errors.image && (
                        <p className="error-text">{errors.image}</p>
                      )}
                    </div>
                  </div>
                </div>
              </Box>
              {/* <Box p={3}>
                <Partners />
                <div className="nextbutton">
                  <button className="next-button" onClick={() => setValue(1)}>
                    <ArrowForwardIcon className="icon" />
                    <span>&nbsp; Next – Share</span>
                  </button>
                </div>
              </Box> */}
            </div>
          )}

          {value === 1 && (
            <Box p={3}>
              <Share
                deploymentId={deployId || ""}
                orgUrl={deployment?.url || "preview"}
              />
              {/* <div className="nextbutton">
                <button className="next-button" style={{ width: '210px' }} onClick={() => setValue(2)}>
                  <ArrowForwardIcon className="icon" />
                  <span>&nbsp; Next – Share Result</span>
                </button>
              </div> */}
            </Box>
          )}

          {value === 2 && (
            <Box p={3}>
              <Result deploymentId={deployId} />
            </Box>
          )}
        </Box>
      </div>
      <Modal
        className={"mui-classes"}
        open={openPopup === "closeDeployment"}
        onClose={handleModalClose}
        style={{ maxWidth: "500px", maxHeight: "382px", margin: "auto" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="modal-container">
            <div className="modal-heading">
              <div className="heading-left">
                <p className="modal-title">Close ongoing deployment</p>
              </div>
              <div className="heading-right">
                <Close
                  onClick={() => {
                    setOpenPopup(false);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="mid-container">
              <div className="alert">
                <img src={Alert} alt="" />
              </div>
              <div className="confirmation-msg">
                <p className="message">
                  Are you sure you want to close {deploymentName}?
                </p>
                <p className="delete-info">
                  Your respondents will no longer<br></br>be able to take this
                  assessment.
                </p>
              </div>
            </div>
            <div className="buttons-container">
              <Button
                className="cancel"
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                Cancel
              </Button>
              <Button className="delete-btn" onClick={() => closeDeployment(deployId)}>
                Close Deployment
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Assessments;



const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 22,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 14,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(10px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#262626',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      }
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16.5,
    height: 16.5,
    borderRadius: 7,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 11,
    opacity: 1,
    backgroundColor: '#262626',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));