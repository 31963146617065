import React, { useEffect, useState } from 'react';
import downloadicon from "../../../Assests/Images/Downloadlogo.svg";
import messageicon from "../../../Assests/Images/MessageIcon.svg";
import { ReactComponent as CopyIcon } from "../../../Assests/Images/CopyIcon.svg"
import GetResources from "../../../Assests/Images/getresource.svg"
import QRCode from "qrcode";
import { useNavigate } from 'react-router-dom';

const Share = ({ orgUrl, deploymentId }) => {
  const navigate = useNavigate()
  const options = ['gloo.us/assessments/lifechurch/deploy245671', 'gloo.us/assessments/church/deploy245671', 'bagloo.us/assessments/lifechurch/deploy245671rna'];
  const [isTooltipVisible, setTooltip] = useState(false);

  const previewSurveyLink =
    window.location.href.includes("dashboard.preview") ||
      window.location.href.includes("localhost")
      ? `https://preview.survey.church/${orgUrl}/deploy${deploymentId}`
      : `https://survey.church/${orgUrl}/deploy${deploymentId}`;

  const handleDownloadQRCode = async () => {
    try {
      const qrCodeDataURL = await QRCode.toDataURL(previewSurveyLink, {
        width: 250,
        margin: 1,
        color: { dark: '#000000', light: '#ffffff' }
      });

      const link = document.createElement("a");
      link.href = qrCodeDataURL;
      link.download = `QRCode_${deploymentId || "default"}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Failed to generate QR Code", error);
    }
  };

  return (
    <div className='share-container'>
      <div className="link share">
        <div className="left-share">
          <div className="heaping-share">
            <p className='headtext'>Unique Link</p>
          </div>
          <p className='unique-description'>Share a link to this assessment. Every individual deployment gets a unique link.</p>
        </div>
        <div className="right-share">
          <div className="copy-link-container">
            <span className="clink">{previewSurveyLink}</span>
            <div className="tooltip">
              <CopyIcon fill={isTooltipVisible ? "#D9F1E1" : "#737373"} onClick={() => {
                setTooltip(true)
                setTimeout(() => {
                  setTooltip(false);
                }, 1000)
                navigator.clipboard.writeText(previewSurveyLink)
              }} />
              <div className="tooltiptext" style={{ visibility: isTooltipVisible ? "visible" : "hidden" }}>
                Link Copied
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="qrcode share">
        <div className="left-share">
          <div className="heaping-share">
            <p className='headtext'>QR Code</p>
          </div>
          <p>Download a QR Code to take people straight to this assessment.</p>
        </div>
        <div className="right-share">
          <button className="cta-share qrdownloadshare" onClick={handleDownloadQRCode}>
            <img src={downloadicon} alt="" />&nbsp; Download Custom QR Code
          </button>
        </div>
      </div>

      {/*
      <div className="deploy share">
        <div className="left-share">
          <div>
            <div className="heaping-share">
              <p className='headtext'>Deploy with gloo messaging</p>
            </div>
            <p className='description'>Instantly send a broadcast to your entire church or automatically send this assessment to new visitors.</p>
          </div>
        </div>
        <div className="right-share">
          <button className="cta-share createbroadcastshare ">
            <img src={messageicon} alt="" />&nbsp; Create a Broadcast
          </button>
          <button className="cta-share workflowsshare ">
            <img src={messageicon} alt="" /> &nbsp; Automate with Workflows
          </button>
        </div>
      </div>*/}

      <div className="state share">
        <div className="left-share">
          <div>
            <div className="heaping-share">
              <p className='headtext'>The State of Your Church Kit</p>
            </div>
            <p className='description'>Access graphics templates, communications resources, and more to help you promote the ChurchPulse.</p>
          </div>
        </div>
        <div className="right-share">
          <button onClick={() => { window.parent.location.href = `https://app.gloo.us/discover/state-of-the-church` }} className="cta-share getresource">
            Get ChurchPulse Resources<img src={GetResources} alt="" />
          </button>
        </div>
      </div>

    </div>
  );
};

export default Share;
