import React, { useState } from 'react'
import Glow from "../../../Assests/Images/Glow.svg"
import glooLogo from "../../../Assests/Images/gloo-logo.svg"
import closebanner from "../../../Assests/Images/close-banner.svg"
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const LimitedTimeBanner = () => {
	const { windowWidth } = useWindowDimensions()
	return (
		<div className='banner-container' id='banner-container-id'>
			<div className="limited-time-banner-container">
				{windowWidth > 560 && <img src={Glow} alt="Glow" className="banner-gradient" />}
				<div className="banner-text-container">
					<div className="left-container">
						<img src={glooLogo} alt="glooLogo" />
						{windowWidth > 560 && (
							<div className="text-wrapper">
								<p className="title">You're enjoying free access to premium dashboard features</p>
								<p className="subtitle">
									Take advantage of this opportunity to explore tools that empower you to know more and lead with confidence.
								</p>
							</div>
						)}
					</div>
					<div className="right-container">
						<button className="banner-cta" onClick={() => window.open("https://app.gloo.us/gloo-plus", '_blank', 'noopener,noreferrer')}>
							See the perks
						</button>
					</div>
				</div>
			</div>
		</div>
	)
	
}

export default LimitedTimeBanner