const fetchNationalAverage = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisations/gloo/nationalAvg`
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
};

const fetchNationalDemographics = async (payload) => {
  console.log(payload);
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisations/gloo/getDemographicNationalAvgByFilters`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data?.data || [];
};

const fetchNationalGrowthGoals = async (payload) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisations/gloo/nationalAvgByFilters/growthGoals`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data?.data || [];
};

const fetchChurchDemographics = async (payload) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisations/gloo/getDemographicChurchAvgByFilters`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  if (data.status === "success") {
    return data?.data || [];
  } else {
    console.error("Failed to fetch church demograhics Data");
    throw new Error(data?.message || "Failed to fetch church demograhics Data");
  }
};

const fetchChurchGrowthGoals = async (payload) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisations/gloo/churchAvgByFilters/growthGoals`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  if (data.status === "success") {
    return data?.data || [];
  } else {
    console.error("Failed to fetch church avg Data");
    throw new Error(data?.message || "Failed to fetch church avg growth goals Data");
  }
};

const fetchNationalAverageByFilter = async (payload) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisations/gloo/nationalAvgByFilters`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  if (data.status === "success") {
    return data;
  } else {
    console.error("Failed to fetch national average Data");
    throw new Error(data?.message || "Failed to fetch national average Data");
  }
};

const fetchChurchData = async (orgId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisations/gloo/${orgId}/churchAvg`
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  if (data.status === "success") {
    return data;
  } else {
    console.error("Failed to fetch church Data");
    throw new Error(data?.message || "Failed to fetch church Data");
  }
};

const fetchChurchDataByFilters = async (payload) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisations/gloo/churchAvgByFilters`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
};

const fetchDashboardData = async (orgId, token) => {
  const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/insights`;
  const response = await fetch(apiUrl, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  if (data.status === "success") {
    return data;
  } else {
    console.error("Failed to fetch insights data");
    throw new Error(data.error);
  }
};

const fetchDemographics = async (orgId, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/demographicsDetails`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  const res = await response.json();
  if (res.status === "success") {
    return res.data;
  } else {
    throw new Error("Error in fetching demographics details");
  }
};

const fetchGrowthGoals = async (orgId, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/growthGoals`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  const res = await response.json();
  if (res.status === "success") {
    return res.data;
  } else {
    throw new Error("Error in fetching demographics details");
  }
};

const fetchSevenDimensions = async (orgId, token) => {
  const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/sevenDimensions`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === "success") {
      let sortedData = data.data.sort(
        (a, b) => b.averageScore - a.averageScore
      );
      return sortedData; // Return sorted data
    } else {
      console.error("Failed to fetch seven dimensions data");
      return null;
    }
  } catch (err) {
    console.error("Error fetching seven dimensions data...", err);
    throw err;
  }
};

const fetchFifteenDimensions = async (orgId, token) => {
  const apiUrl = `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/fifteenDimensions`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.status === "success") {
      let sortedData = data.data.sort(
        (a, b) => b.averageScore - a.averageScore
      );
      return sortedData;
    } else {
      console.error("Failed to fetch fifteen dimensions data");
      return null;
    }
  } catch (err) {
    console.error("Error fetching fifteen dimensions data...", err);
    throw err;
  }
};

const fetchNationalAverageDataByCategory = async (payload, catId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisation/gloo/getNationalAverage/categoryByFilters/${catId}`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  if (data.status === "success") {
    return data?.data;
  } else {
    throw new Error("Failed to fetch national average data");
  }
};

const fetchOrganizationDataByCategory = async (payload, catId) => {
  console.log({payload})
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisation/gloo/statistics/categoryByFilters/${catId}`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  if (data.status === "success") {
    return data?.data;
  } else {
    throw new Error("Failed to fetch organization data");
  }
};

const fetchDeploymentData = async (orgId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/statistics/AIInsights`,
      {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === "success") {
      const values = data.data.map((item) => item.value);
      return values;
    } else {
      console.error("Unexpected status in response:", data.status);
      return [];
    }
  } catch (error) {
    console.error("Error fetching deployment data:", error.message);
  }
};

const fetchOrganizationDemographicDetails = async (payload, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisation/gloo/statistics/demographicsDetailsByFilters`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data?.data;
};

const fetchNationalAverageDemographicDetails = async (payload, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisation/gloo/getNationalAverage/demographicsDetailsByFilters`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data?.data;
};

const fetchCircleInformation = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisation/gloo/health/orgDetails`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data?.data;
};

const closeDeploymentMutation = async (orgId, payload, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v4/organisation/gloo/${orgId}/deployments`,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(payload),
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data;
};

const updateURLDeployment = async (orgId, token, newUrl) => {
  const requestBody = {
    properties: [{ property: "url", value: newUrl }],
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URI}/v5/organisation/gloo/details/${orgId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to update URL");
  }

  return response.json();
};

const fetchOrgStatus = async (orgId, token) => {
  const apiUrl = `${process.env.REACT_APP_API_URI}/v5/organisations/gloo/${orgId}/status`;
  const response = await fetch(apiUrl, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  if (data.status === "success") {
    return data;
  } else {
    console.error("Failed to fetch insights data");
    throw new Error(data.error);
  }
};


export {
  fetchCircleInformation,
  fetchNationalAverage,
  fetchNationalAverageByFilter,
  fetchDashboardData,
  fetchSevenDimensions,
  fetchFifteenDimensions,
  fetchDemographics,
  fetchGrowthGoals,
  fetchChurchData,
  fetchChurchDataByFilters,
  fetchNationalDemographics,
  fetchChurchDemographics,
  fetchNationalGrowthGoals,
  fetchChurchGrowthGoals,
  fetchNationalAverageDataByCategory,
  fetchOrganizationDataByCategory,
  fetchDeploymentData,
  fetchOrganizationDemographicDetails,
  fetchNationalAverageDemographicDetails,
  closeDeploymentMutation,
  updateURLDeployment,
  fetchOrgStatus
};
