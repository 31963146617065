import React, { useState, useEffect, useContext } from "react";
import { ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../components/DemographicsDetailsNew/DemographicsDetailsNew.scss";
import LeftArrow from '../../../Assests/Images/leftarrow.svg';
import { useDemographicsDetailsData } from "../../../hooks/useQueryAPI";
import bulb from "../../../Assests/Images/aboutblack.svg";
import { ShimmerDiv, ShimmerTitle, ShimmerBarChart } from "shimmer-effects-react";
import AuthContext from "../../../store/AuthContext";
import MobileViewChart from "../../ChurchHealthMobile/DemographicsDetailsMobile/DemographicsDetailsMobile";
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ArrowRight from '../../../Assests/Images/arrow-right-white.svg';
import { getAllCircleIds, navigateToCircles } from "../../../utils";

const DemographicsDetails = () => {
  const { windowWidth } = useWindowDimensions();
  const authCtx = useContext(AuthContext);
  const [tabs, setTabs] = useState([]);
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("ethnicity");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredIndexTooltip, setHoveredIndexTooltip] = useState(null);
  const [chartData, setChartData] = useState([]);
  const navigate = useNavigate()
  const [currentQuestion, setCurrentQuestion] = useState({ questionNo: "", question: "" });
  const { isDeployedDone, isComparisonBarChart, isChurch } = location.state || {};

  const [filters, setFilters] = useState({
    appliedFiltersPrimary: {},
    appliedFiltersSecondary: {},
  });

  useEffect(() => {
    let filters = {}
    try {
      filters = JSON.parse(localStorage.getItem("church-health-fitlers"));
      if (filters) {
        setFilters(filters);
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  const getCircleIds = (filters = {}) => filters?.dataset === "Circles" ? getAllCircleIds(authCtx?.circles || [], filters?.circleType) : [];

  const {
    data: primaryData = [],
    isLoading: isPrimaryLoading,
  } = useDemographicsDetailsData({ ...filters?.appliedFiltersPrimary, orgIds: [authCtx.orgId, ...getCircleIds(filters?.appliedFiltersPrimary)] }, authCtx.token, isDeployedDone)

  const {
    data: secondaryData = [],
    isLoading: isSecondaryLoading,
  } = useDemographicsDetailsData({ ...filters?.appliedFiltersSecondary, orgIds: [authCtx.orgId, ...getCircleIds(filters?.appliedFiltersSecondary)] }, authCtx.token)

  useEffect(() => {
    if (!isPrimaryLoading && !isSecondaryLoading) {
      setLoading(false);

      const tabData = primaryData.length > 0 ? primaryData : secondaryData;
      const newTabs = tabData.map(item => item.questionCategory);
      setTabs(newTabs);
      updateChartData(primaryData, secondaryData, activeTab);
    }

  }, [isPrimaryLoading, isSecondaryLoading]);

  const normalizeHeights = (data) => {
    const totalSecondaryData = data.reduce((sum, item) => sum + parseInt(item.secondaryData || 0), 0);
    const totalPrimaryData = data.reduce((sum, item) => sum + parseInt(item.primaryData || 0), 0);

    const roundingValues = {primary: {lowest: [0, 0], highest: [0, 1]}, secondary: {lowest: [0, 0], highest: [0, 1]}};

    let primarySum = 0, secondarySum = 0;

    const chartData = data.map((item, index) => {
      const secondaryHeight = totalSecondaryData > 0
        ? (item.secondaryData / totalSecondaryData) * 100
        : 0;
      const decimal = secondaryHeight - Math.floor(secondaryHeight);

      if ( decimal > roundingValues.secondary.lowest[1] ) {
        roundingValues.secondary.lowest[0] = index;
        roundingValues.secondary.lowest[1] = decimal;
      }

      if ( decimal < roundingValues.secondary.highest[1] && secondaryHeight > 1 ) {
        roundingValues.secondary.highest[0] = index;
        roundingValues.secondary.highest[1] = decimal;
      }

      const primaryHeight = (!isDeployedDone && secondaryHeight === 0) ? 0 : (item.primaryData / totalPrimaryData) * 100;

      const primDecimal = primaryHeight - Math.floor(primaryHeight);

      if ( primDecimal > roundingValues.primary.lowest[1] ) {
        roundingValues.primary.lowest[0] = index;
        roundingValues.primary.lowest[1] = primDecimal;
      }

      if ( primDecimal < roundingValues.primary.highest[1] && primaryHeight > 1  ) {
        roundingValues.primary.highest[0] = index;
        roundingValues.primary.highest[1] = primDecimal;
      }

      primarySum += Math.round(primaryHeight);
      secondarySum += Math.round(secondaryHeight);

      return {
        ...item,
        normalizedPrimaryHeight: Math.round(primaryHeight),
        normalizedSecondaryHeight: Math.round(secondaryHeight)
      };
    });

    if ( primarySum < 100 ) {
      console.log("primary Sum < 100");
      chartData[roundingValues.primary.lowest[0]].normalizedPrimaryHeight += 1;
    }

    if ( primarySum > 100 ) {
      console.log("primary Sum > 100");
      chartData[roundingValues.primary.highest[0]].normalizedPrimaryHeight -= 1;
    }

    if ( secondarySum < 100 ) {
      console.log("secondary Sum < 100");
      chartData[roundingValues.secondary.lowest[0]].normalizedSecondaryHeight += 1;
    }

    if ( secondarySum > 100 ) {
      console.log("secondary Sum > 100");
      chartData[roundingValues.secondary.highest[0]].normalizedSecondaryHeight -= 1;
    }

    return chartData;
  };

  const updateChartData = (primaryData = [], secondaryData = [], category) => {
    const primaryCategoryData = primaryData.find(item => item.questionCategory.toLowerCase() === category.toLowerCase());
    const secondaryCategoryData = secondaryData.find(item => item.questionCategory.toLowerCase() === category.toLowerCase());

    let secondaryformattedData = Object.entries(secondaryCategoryData?.answerCount || {}).map(([key, value]) => {
      // console.log(secondaryCategoryData.answers, Number(key), secondaryCategoryData.answers.find(scd => scd.value === Number(key)))
      let dt = { ...(secondaryCategoryData.answers.find(scd => scd.value === Number(key))) }
      dt.key_value = dt.value.toString();
      dt.value = value;
      return ({
        name: dt?.desktop_text,
        ...dt
      })
    });

    secondaryformattedData = secondaryformattedData.sort((a, b) => a.order_no - b.order_no)

    const combinedData = secondaryformattedData.map((item, index) => {
      const primaryAnswer = primaryCategoryData?.answerCount[item.key_value] || "";
      return {
        name: item.name,
        primaryData: isDeployedDone ? primaryAnswer : Math.floor(Math.random() * 100) + 1,
        secondaryData: item.value,
      };
    });
    console.log(combinedData);

    const normalizedData = normalizeHeights(combinedData);
    console.log(normalizedData);
    setChartData(normalizedData);

    setCurrentQuestion({
      questionNo: secondaryCategoryData?.questionNo,
      question: secondaryCategoryData?.question,
      primaryanswers: primaryCategoryData?.answers,
      secondaryanswers: secondaryCategoryData?.answers
    });
  };

  useEffect(() => {
    if (!isLoading) {
      updateChartData(primaryData, secondaryData, activeTab);
    }
  }, [activeTab, primaryData, secondaryData, isLoading]);

  const handleMouseEnter = (index) => {
    setHoveredIndexTooltip(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndexTooltip(null);
  };

  return (
    <div className="comparative-demographics-container">
      <div className="demographics-header">
        <ArrowBack onClick={() => navigate(-1)} fontSize="large" style={{ width: '24px', height: '24px', cursor: "pointer" }} />
        <h1 className="pagename">Demographics</h1>
      </div>
      <div className="demographics-first-container">
        <div className="first-inner-container">
          <div className="cards">
            {!isLoading ?
              <div className="card">
                <div className="dimension-title">
                  <img src={bulb} alt="Bulb Icon" />
                  <p>{windowWidth > 560 ? 'Demographics' : 'About this Dimension'}</p>
                </div>
                <div className="statement-box">
                  <p> A comprehensive demographics dashboard provides church leaders with key insights into their congregation's composition and trends. At a glance, leaders can view age distribution, household types, and attendance patterns, along with church engagement levels. This data can help inform ministry planning by highlighting areas of growth, identifying underserved populations, and tracking community changes over time. </p>
                </div>
              </div>
              : <div style={{ opacity: isLoading ? "50%" : "100%" }}><ShimmerDiv mode="light" height={250} width={1046} rounded={2} /></div>
            }

          </div>
        </div>
        <div className="third-inner-container">
          <div className="cards">
            <div className="card">
              <div className="tab-container">
                {
                  !isLoading ?
                    <div className="tabs">
                      {tabs.map((tab) => (
                        <button key={tab} className={`tab-button ${activeTab === tab ? "active" : ""}`} onClick={() => setActiveTab(tab)}>
                          {tab === 'maritalStatus' ? 'Marital Status' : tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </button>
                      ))}
                    </div>
                    : <ShimmerTitle mode="light" line={1} gap={3} />
                }

              </div>
              <div className="question-container">
                {!isLoading ? (
                  <React.Fragment key={activeTab}>
                    <div className="dimension-title" >
                      <p>Question {currentQuestion.questionNo}</p>
                      <span>{currentQuestion.question}</span>
                    </div>
                    {windowWidth > 560 ? (
                      <div className="chart-container">
                       {chartData?.map((data, index) => {
                          const { name, primaryData, secondaryData, normalizedPrimaryHeight, normalizedSecondaryHeight} = data;
                          return (
                            <div className="chart" key={index}>
                              <div 
                                onMouseEnter={() =>  setHoveredIndex(index)}
                                onMouseLeave={() =>  setHoveredIndex(null)}
                                style={{ opacity:  hoveredIndex !== null ? (hoveredIndex === index ? 1 : 0.5) : 1}}
                                className="chart-bar" >
                                <div className="databar-one">
                                  <span className='databar-one-percentage'
                                    style={{ filter: (!isDeployedDone && isComparisonBarChart) ? "blur(5px)" : "none",
                                      color: !isDeployedDone && isComparisonBarChart ? "#D4D4D4" : isComparisonBarChart ? "#C581FF" : "#171717" }}>
                                    {normalizedPrimaryHeight || 0}%
                                  </span>
                                  <div  className={`dataheightone-container ${!!!filters?.comparison && "comparison-inactive"}`} onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={handleMouseLeave}  onTouchStart={(e) => {
                                    e.stopPropagation();
                                    handleMouseEnter(index);
                                  }}>
                                    <div style={{
                                      height: `${normalizedPrimaryHeight || 0}%`,
                                      backgroundColor: (!isDeployedDone && isComparisonBarChart) ? '#D4D4D4' : '#C581FF',
                                      filter: (!isDeployedDone && isComparisonBarChart) ? "blur(5px)" : "none",
                                    }} className="dataheightone" />

                                    {!isDeployedDone && index === hoveredIndexTooltip && (
                                      <div className='tooltip-container'>
                                        <p>Discover how your people are doing by deploying the Church Pulse.</p>
                                        <div className='btn-box'>
                                          <button className="tool-tip-btn" onClick={() => isChurch ? navigate("/assessments/details") : navigateToCircles()}>
                                            {isChurch ? "Deploy Assessment" : "Create your Circle"}
                                            <img src={ArrowRight} height={11} alt="ArrowRight Icon" />
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  
                                </div>
                                {!!filters?.comparison && (
                                <div className="databar-two">
                                  {isComparisonBarChart && (
                                    <span className='databar-two-percentage'>
                                      {normalizedSecondaryHeight || 0}%
                                    </span>
                                  )}
                                  <div className="dataheighttwo-container">
                                    <div style={{
                                      height: `${normalizedSecondaryHeight || 0}%`}} className="dataheighttwo" />
                                  </div>
                                </div>
                                )} 
                              </div>
                              <div className="chartlabel">{name}</div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <MobileViewChart data={chartData} isChurch={isChurch}/>
                    )}
                    <div className="lableaboutdata">
                      <div className="mydata">
                        <div className='circle' style={{ backgroundColor: (!isDeployedDone && isComparisonBarChart) ? '#D4D4D4' : '#C581FF' }}></div>
                        <p className='dataname'>My Data</p>
                      </div>
                      {isComparisonBarChart &&
                        <div className="nationalaverage">
                          <div className='circle'></div>
                          <p className='dataname'>National Average</p>
                        </div>
                      }
                    </div>
                  </React.Fragment>)
                  : (<ShimmerBarChart mode="light" chartType="random" barWidth={"7%"} style={{ paddingTop: 10 }} />)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicsDetails;