import React, { useEffect, useState } from "react";
import { ReactComponent as AiInsightIcon } from "../../../Assests/Images/ChurchHealth/icons/AiInsightsIcon.svg";
import Arrow from "../../../Assests/Images/arrow-right-black.svg";
import ComparisonBarChart from "../ComparisonBarChart/ComparisonBarChart";
import NonComparisonBarChart from "../../ChurchHealth/NonComparisonBarChart/NonComparisonBarChart";
import { useNavigate } from "react-router-dom";
import { AIInsightsPeopleFlourishing } from "../AIInsightsPeopleFlourishing/AIInsightsPeopleFlourishing";
import { ShimmerBarChart } from "shimmer-effects-react";

// Create chart data using the averages
const chartDataTemplate = [
  { name: "Relationships", variable: "cprelation", mydata: 32 },
  { name: "Purpose", variable: "cppurpose", mydata: 34 },
  { name: "Spiritual", variable: "cpspiritual", mydata: 84 },
  { name: "Character", variable: "cpcharacter", mydata: 27 },
  { name: "Finances", variable: "cpfinance", mydata: 48 },
  { name: "Contentment", variable: "cpcontent", mydata: 63 },
  { name: "Health", variable: "cphealth", mydata: 63 },
];

const PeopleFlourishing = ({
  isDeployedDone,
  primaryData,
  primaryColor,
  secondaryColor,
  secondaryData,
  comparison,
  dimensionCatIds,
  primaryLabel,
  secondaryLabel,
  isChurch
}) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (primaryData && secondaryData && Array.isArray(dimensionCatIds)) {
      const flourishingVariables = [
        "cprelation",
        "cppurpose",
        "cpspiritual",
        "cpcharacter",
        "cpfinance",
        "cpcontent",
        "cphealth",
      ];

      const primAverageScores = {};

      // Create an object to store the averages
      const secAverageScores = {};
      // Populate the averages
      flourishingVariables.forEach((variable) => {
        const item = secondaryData.find((data) => data.variable === variable);
        secAverageScores[variable] = Math.floor(item?.average_score || 0);

        const prItem = primaryData.find((data) => data.variable === variable);
        primAverageScores[variable] = Math.floor(prItem?.average_score || 0);
      });

      const newData = chartDataTemplate.map(({ name, variable }) => {
        const dimension = dimensionCatIds.find(
          (dim) => dim.variable === variable
        );
        return {
          name,
          mydata: primAverageScores[variable],
          nationalaverage: secAverageScores[variable],
          catId: dimension?.catId || null,
        };
      });
      setChartData(newData);
      if(newData){
        setIsLoading(false);
      }
    }
    // if(chartData?.length > 0){
    //   setLoading(false);
    // }
  }, [primaryData, secondaryData]);

  return (
    <div className="people-flourishing-container">
      <div className="people-flourishing-title">People Flourishing</div>
      <p className="people-flourishing-subtitle">
        7 dimensions of whole-person health. How are my people
        doing and where do they need support?
      </p>

      <div className="people-flourishing-chart-section">
        {isLoading ?
          <div className="barcontainershimmer" style={{ paddingTop: 10, height: "367px", width: "100%", opacity:0.5 }}>
            <ShimmerBarChart mode="light" chartType="random" barWidth="5%"/>
          </div>
          : comparison ? (
            <ComparisonBarChart
              isDeployedDone={isDeployedDone}
              data={chartData}
              primaryColor={primaryColor}
              isChurch={isChurch}
              comparison={comparison}
            />
          ) : (
            <NonComparisonBarChart
              isDeployedDone={isDeployedDone}
              data={chartData}
            />
          )}
      </div>
      {comparison && <div className="people-flourishing-chart-legend">
        <div className="people-flourishing-chart-legend-item">
          <span
            className="grey-dot"
            style={{ backgroundColor: primaryColor }}
          ></span>
          {primaryLabel}
        </div>
          <div className="people-flourishing-chart-legend-item">
            <span className="green-dot"></span>
            {secondaryLabel}
          </div>
      </div>}
      <AIInsightsPeopleFlourishing isDeployedDone={isDeployedDone}  isChurch={isChurch}/>
    </div>
  );
};

export default PeopleFlourishing;
